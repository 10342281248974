<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card>
          <v-card-title>
            Schedule
          </v-card-title>
          <v-container>
            <v-row>
              <v-col md="2" cols="12">
                <v-autocomplete v-model="airlineSearch" label="Airline Name" :items="airlines" item-text="name" item-value="icao" clearable></v-autocomplete>
              </v-col>
              <v-col md="2" cols="5">
                <v-autocomplete v-model="airportOriginSearch" label="Origin Airport" :items="sortedAirports" :item-text="item => item.identifier + ' - ' + item.name" item-value="identifier" clearable></v-autocomplete>
              </v-col>
              <v-col md="1" cols="2" class="d-flex align-center justify-center">
                <v-btn icon v-on:click="swapOriginDestination()"><v-icon>mdi-swap-horizontal</v-icon></v-btn>
              </v-col>
              <v-col md="2" cols="5">
                <v-autocomplete v-model="airportDestinationSearch" label="Destination Airport" :items="sortedAirports" :item-text="item => item.identifier + ' - ' + item.name" item-value="identifier" clearable></v-autocomplete>
              </v-col>
              <v-col md="2" cols="8">
                <v-select v-model="eteSort" :items="['ASC', 'DESC']" label="Sort ETE"></v-select>
              </v-col>
              <v-col md="2" cols="4" class="d-flex align-center">
                <v-btn v-on:click="loadData()" :disabled="loading">Search</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-data-table :headers="headers" :page="page" :pageCount="pageCount" :options.sync="options" :server-items-length="totalItems" :loading="loading" :items="realFlights" :items-per-page="10" :footer-props="{'items-per-page-options': [10, 20, 50, 100]}" disable-sort>
            <template #item.eta_minutes="{ item }">
              {{etaMinutes(item.eta_minutes)}}
            </template>
            <template #item.actions="{ item }">
              <div class="d-flex">
                <v-btn v-on:click="dispatch(item.id)" color="primary" style="margin-right: 5px">Dispatch</v-btn>
                <v-btn v-on:click="schedule(item.id)" color="warning">Schedule</v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import Shared from "@/components/Shared";

export default {
  name: "RealFlights",
  props: ['tab', 'dispatchTab', 'dispatchData', 'addFlightSchedule', 'scheduleData', 'airports'],
  mounted: function (){
    this.getAirlines()
  },
  data: function(){
    return {
      headers: [
        {
          text: 'Airline',
          value: 'airline'
        },
        {
          text: 'Flight Number',
          value: 'flight_number'
        },
        {
          text: 'Origin',
          value: 'origin'
        },
        {
          text: 'Destination',
          value: 'destination'
        },
        {
          text: 'Estimated',
          value: 'eta_minutes'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'end',
          width: '1%'
        },
      ],
      airlines: [],
      realFlights: [],
      page: 1,
      pageCount: 0,
      totalItems: 0,
      loading: false,
      options: {},
      airlineSearch: undefined,
      airportOriginSearch: undefined,
      airportDestinationSearch: undefined,
      eteSort: "DESC",
      lastTotalCount: 0,
      firstLoad: true
    }
  },
  watch: {
    options: {
      handler(){
        if (this.firstLoad){
          this.firstLoad = false
          return
        }
        this.realFlights = []
        this.readDataFromAPI(true)
      }
    }
  },
  computed: {
  ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    sortedAirports: function(){
      return [...this.airports].sort(function (a, b) {
        return a['identifier'].toUpperCase().localeCompare(b['identifier'].toUpperCase())
      })
    }
  },
  methods: {
    loadData: function(){
      this.resetPagination()
      this.readDataFromAPI(false)
    },
    readDataFromAPI: function(skipCountLoaderArg){
      if (this.airlineSearch === undefined && this.airportOriginSearch === undefined && this.airportDestinationSearch === undefined){
        console.log("TEST")
        return
      }

      this.loading = true
      const { page, itemsPerPage } = this.options
      let pageNumber = page - 1
      let offset = pageNumber * itemsPerPage

      const airlineICAO = this.airlineSearch === undefined ? "":this.airlineSearch
      const airportOriginSearch = this.airportOriginSearch === undefined ? "":this.airportOriginSearch
      const airportDestinationSearch = this.airportDestinationSearch === undefined ? "":this.airportDestinationSearch

      const shouldSkipCountLoader = skipCountLoaderArg && this.lastTotalCount !== 0
      const skipTotalCountQuery = shouldSkipCountLoader ? "&skip_total_count=true" : "&skip_total_count=false"

      axios.get(
        this.$baseURL + "/realFlightSchedule?limit="+itemsPerPage+"&offset="+offset+"&airline_icao="+airlineICAO+"&origin="+airportOriginSearch+"&destination="+airportDestinationSearch+"&orderASC="+(this.eteSort === "ASC") + skipTotalCountQuery
      ).then((response) =>{
        this.loading = false

        const results = response.data.result.results
        if (results === null){
          this.realFlights = []
        }else{
          this.realFlights = response.data.result.results
        }

        if (shouldSkipCountLoader){
          this.totalItems = this.lastTotalCount
          this.pageCount = this.lastTotalCount / itemsPerPage
        }else{
          this.totalItems = response.data.result.totalCount
          this.pageCount = response.data.result.totalCount / itemsPerPage
          this.lastTotalCount = this.totalItems
        }
      })
    },
    resetPagination: function(){
      this.page = 1
      this.pageCount =  0
      this.totalItems = 0
      this.realFlights = []
      this.options.page = 1
    },
    etaMinutes: function(minutes){
      return Shared.methods.formatMinutesToTime(minutes)
    },
    getAirlines: function(){
      axios.get(
        this.$baseURL + "/airlines?username="+ this.currentUser['user']
      ).then((response) => {
        if (response.status === 200){
          this.airlines = response.data
        }
      })
    },
    dispatch: function(ID) {
      const flight = this.realFlights.filter(d => d.id === ID)[0]
      this.$emit('update:dispatchTab', 0)
      this.$emit('update:dispatchData', Object.assign({},
        {
          'origin': flight['origin'],
          'destination': flight['destination'],
          'flight_number': flight['raw_flight_number'],
          'airline': {
            'icao': flight['airline_icao'],
            'iata': flight['airline_iata']
          }
        }))
    },
    schedule: function(ID){
      const flight = this.realFlights.filter(d => d.id === ID)[0]
      this.$emit('update:tab', 0)
      this.$emit('update:addFlightSchedule', true)
      this.$emit('update:scheduleData', Object.assign({}, {
        "origin": flight['origin'],
        "destination": flight['destination'],
        "flight_number": flight['raw_flight_number'],
      }))
    },
    swapOriginDestination: function(){
      let temp = this.airportOriginSearch
      this.airportOriginSearch = this.airportDestinationSearch
      this.airportDestinationSearch = temp
    }
  }
}
</script>

<style scoped>

</style>
