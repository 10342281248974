<template>
  <div class="text-center">
    <v-dialog v-model="internalDialogState" width="unset">
      <v-card>
        <v-card-title class="headline">
          Flight Data
        </v-card-title>
        <v-container v-if="this.currentOFP" v-html="this.currentOFP['text']['plan_html']">
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="internalDialogState = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "OFPModal",
  props: ['ofpDialogState', 'currentOFP'],
  computed: {
    internalDialogState: {
      get: function () {
        return this.ofpDialogState
      },
      set: function(val){
        this.$emit('update:ofpDialogState', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
