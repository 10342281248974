<template>
  <v-dialog v-model="internalDialogState">
    <v-card>
      <v-card-title class="headline">
        Flight Data
      </v-card-title>
      <v-container v-if="loading || aircraftLoading || landingLoading">
        <v-row  style="height: 70vh;">
          <v-col style="text-align: center; align-items: center; justify-content: center; display: flex; flex-direction: column">
            <v-progress-circular
              :size="150"
              :width="5"
              color="amber"
              indeterminate
            ></v-progress-circular>
            <h1 style="margin: 0.67em">
              Loading...
            </h1>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else-if="flightData !== null && flight !== null">
        <v-row>
          <v-col md="3" cols="12" style="display: flex; flex-direction: column">
            <div style="margin-bottom: 1rem">
              <v-row style="align-items: center; margin:0">
                <div style="text-align: center">
                  <h1>{{flight['origin']}}</h1>
                  <p>RW{{flight['origin_rwy']}}</p>
                </div>
                <div class="flex-grow-1">
                  <h4 style="text-align: center">Distance: {{flightDistance}} nm</h4>
                  <v-progress-linear :value="100" striped height="10" style="width: auto; margin-left: 5rem; margin-right: 5rem"></v-progress-linear>
                  <h4 style="text-align: center">{{msToTime(flight['in_flight_length'])}}</h4>
                </div>
                <div style="text-align: center">
                  <h1>{{flight['destination']}}</h1>
                  <p>RW{{flight['destination_rwy']}}</p>
                </div>
              </v-row>
            </div>

            <v-divider/>
            <h4>Details</h4>
            <v-divider/>
            <div class="data-row">
              <p class="alignedItems">
                <span>Origin:</span>
                <span>{{flight['origin']}}</span>
              </p>
              <p class="alignedItems">
                <span>Destination:</span>
                <span>{{flight['destination']}}</span>
              </p>
            </div>
            <div class="data-row" v-if="origin != null && destination != null">
              <p class="alignedItems">
                <span>Runway:</span>
                <span>RW{{flight['origin_rwy']}}</span>
              </p>
              <p class="alignedItems">
                <span>Runway:</span>
                <span>RW{{flight['destination_rwy']}}</span>
              </p>
            </div>
            <template v-if="flight != null">
              <div class="data-row">
                <p class="alignedItems">
                  <span>Distance:</span>
                  <span>{{ flightDistance }} nm</span>
                </p>
                <p class="alignedItems">
                  <span>Fuel:</span>
                  <span v-if="flightData['fuel_used'] > 0">{{ flightData["fuel_used"] }} kg</span>
                  <span v-else>Unknown</span>
                </p>
              </div>
              <div class="data-row">
                <p class="alignedItems">
                  <span>Flight Time: </span>
                  <span>{{ msToTime(flight['in_flight_length']) }}</span>
                </p>
                <p class="alignedItems"></p>
              </div>

              <div style="margin-top: auto">
                <div style="display: flex; flex-direction: column;">
                  <v-tooltip v-model="show" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn color="success"  v-on:click="copyShare(flight['id']);">Copy Share Link</v-btn>
                    </template>
                    <span> Copied link to clipboard! </span>
                  </v-tooltip>

                  <v-btn color="success" v-on:click="openShare(flight['id'])" style="margin-top: 1rem">View Share</v-btn>
                  <v-btn color="primary" v-on:click="downloadCSV(flight['id'])" style="margin-top: 1rem">Download CSV</v-btn>
                </div>
              </div>
            </template>
          </v-col>
          <v-col md="5" cols="12">
            <l-map :zoom="zoom" :center="mapCenter" style="height: 50vh" :options="{worldCopyJump: true}">
              <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
              <l-polyline :lat-lngs="routeLatLngs" color="green"></l-polyline>
              <l-polyline :lat-lngs="latlngs" ></l-polyline>
              <l-circle-marker v-for="(latLng, index) in routeLatLngs" :lat-lng="latLng" :radius="4" color="red">
                <l-tooltip>
                  {{routeWaypoints[index]}}
                </l-tooltip>
              </l-circle-marker>
            </l-map>
          </v-col>
          <v-col md="4" cols="12" style="height: 50vh; overflow:auto">
            <template  v-if="ofpDoc">
              <div v-html="ofpDoc['text']['plan_html']"></div>
            </template>
            <template v-else>
              OFP Unavailable
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="8" cols="12">
            <FlightModalGraphTwoAxes :flight-data="flightData"  :filter-field="['ground_speed', 'altitude']" :labels="['Ground Speed (kts)', 'Altitude (ft)']" title="Ground Speed & Altitude" titleType="h2"/>
          </v-col>
          <v-col md="4" cols="12" style="margin-top:1rem">
            <hr>
            <h2>Aircraft Information</h2>
            <hr>
            <template v-if="aircraft !== null">
              <div class="data-row">
              <p class="alignedItems">
                <span>Tail Number:</span>
                <span>{{aircraft['aircraft']['registration']}}</span>
              </p>
              </div>
              <div class="data-row">
              <p class="alignedItems">
                <span>Airframe:</span>
                <span>{{aircraft['airframe']['name']}}</span>
              </p>
              </div>
              <div class="data-row">
              <p class="alignedItems">
                <span>Airframe ICAO:</span>
                <span>{{aircraft['airframe']['icao']}}</span>
              </p>
              </div>
              <div class="data-row">
              <p class="alignedItems">
                <span>Airline:</span>
                <span>{{aircraft['airline']['name']}}</span>
              </p>
              </div>
              <div class="data-row">
              <p class="alignedItems">
                <span>Callsign:</span>
                <span>{{flight['callsign']}}</span>
              </p>
              </div>
              <div class="data-row">
              <p class="alignedItems">
                <span>Flight Number:</span>
                <span>{{flight['flight_number']}}</span>
              </p>
              </div>
            </template>
            <template v-else>
              <div class="d-flex fill-height align-center justify-center">
                <h2>Data Missing</h2>
              </div>
            </template>
          </v-col>
        </v-row>
        <template v-if="landing !== null" v-for="(landingDataItem, index) in landing">
          <div style="margin-top: 1rem">
            <hr>
            <h1 style="text-align: center">Landing Data</h1>
            <hr>
          </div>

          <v-row>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <l-map :zoom="15" :center="landingLocations[index]" style="z-index: 0; height:30rem; flex-grow: 1" ref="landingMap" :options="{worldCopyJump: true}">
                <l-tile-layer :url="satelliteURL" :attribution="attribution"></l-tile-layer>
                <l-circle-marker v-if="thresholdMarker[index] != null" :lat-lng="thresholdMarker[index]" :radius="2" :color="'blue'"></l-circle-marker>
                <l-circle-marker :lat-lng="landingLocations[index]" :radius="2" :color="'red'"></l-circle-marker>
              </l-map>
            </v-col>

            <v-col cols="12" md="6" class="d-flex" style="flex-direction: column">
              <template>
                <v-divider/>
                <h4>Runway Data</h4>
                <v-divider/>

                <div class="data-row">
                  <p class="alignedItems">
                    <span>Runway:</span>
                    <span>{{destination['runway_identifier']}}</span>
                  </p>
                  <p class="alignedItems">
                    <span>Elevation:</span>
                    <span>{{destination['elevation']}} ft</span>
                  </p>
                </div>

                <div class="data-row">
                  <p class="alignedItems">
                    <span>Length:</span>
                    <span>{{destination['length']}} ft</span>
                  </p>
                  <p class="alignedItems">
                    <span>Width:</span>
                    <span>{{destination['width']}} ft</span>
                  </p>
                </div>

                <div class="data-row">
                  <p class="alignedItems">
                    <span>Heading:</span>
                    <span>{{destination['magnetic_bearing']}} &#176;</span>
                  </p>
                  <p class="alignedItems">
                    <span>Slope:</span>
                    <span>{{destination['gradient']}} &#176;</span>
                  </p>
                </div>
              </template>

              <v-divider/>
              <h4>Landing Data</h4>
              <v-divider/>

              <div class="data-row">
                <p class="alignedItems">
                  <span>Heading:</span>
                  <span>{{radians_to_degrees(landingDataItem['landing']['report']['heading_mag']).toFixed(2)}} &#176;</span>
                </p>
                <p class="alignedItems">
                  <span>Bank:</span>
                  <span>{{radians_to_degrees(landingDataItem['landing']['report']['bank'] * -1).toFixed(2)}} &#176;</span>
                </p>
              </div>
              <div class="data-row">
                <p class="alignedItems">
                  <span>Pitch:</span>
                  <span>{{radians_to_degrees(landingDataItem['landing']['report']['pitch'] * -1).toFixed(2)}} &#176;</span>
                </p>
                <p class="alignedItems">
                  <span>Speed:</span>
                  <span>{{landingDataItem['landing']['report']['ground_speed'].toFixed(0)}} Kts</span>
                </p>
              </div>

              <div class="data-row">
                <p class="alignedItems">
                  <span>
                    Vertical Speed:
                  </span>
                  <span v-if="'vertical_speed_touchdown_value' in landingDataItem['landing']['report']">
                    -{{ (landingDataItem['landing']['report']['vertical_speed_touchdown_value'] * 60).toFixed(0)}} fpm
                  </span>
                  <span v-else>
                    {{ (landingDataItem['landing']['report']['vertical_speed_aircraft'] * 60).toFixed(0)}} fpm
                  </span>
                </p>
                <p class="alignedItems">
                  <span>G Force:</span>
                  <span>{{landingDataItem['landing']['report']['g_force'].toFixed(2)}} G</span>
                </p>
              </div>

              <div class="data-row" v-if="'cross_wind' in landingDataItem['landing']['report']  && 'head_wind' in landingDataItem['landing']['report']">
                <p class="alignedItems">
                  <span>
                    Crosswind:
                  </span>
                  <span>
                    {{landingDataItem['landing']['report']['cross_wind'].toFixed(2) }} Kts
                  </span>
                </p>
                <p class="alignedItems">
                  <span>
                    Tailwind:
                  </span>
                  <span>
                    {{ landingDataItem['landing']['report']['head_wind'].toFixed(2) }} Kts
                  </span>
                </p>
              </div>

              <v-row>
                <v-col md="6" cols="12">
                  <FlightModalGraph :flight-data="{'flight_data_arr': landingDataItem['approach']}" filter-field="cross_wind" title="Cross Wind" titleType="h3"/>
                </v-col>
                <v-col md="6" cols="12">
                  <FlightModalGraph :flight-data="{'flight_data_arr': landingDataItem['approach']}" filter-field="head_wind" title="Tail Wind" titleType="h3"/>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </template>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="internalDialogState = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import FlightModalGraph from "@/components/FlightModalGraph";
import L from "leaflet";
import airplaneIcon from "@/assets/icons/airplane.png";

import {
  LCircleMarker,
  LControlAttribution,
  LIcon,
  LMap,
  LMarker,
  LPolyline,
  LPopup,
  LTileLayer,
  LTooltip
} from "vue2-leaflet";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import Shared from "@/components/Shared";
import FlightModalGraphTwoAxes from "@/components/FlightModalGraphTwoAxes";

export default {
  name: 'FlightModal',
  components: {
    FlightModalGraph,
    FlightModalGraphTwoAxes,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LCircleMarker,
    LControlAttribution,
    LIcon,
    'v-rotated-marker': Vue2LeafletRotatedMarker,
    LPolyline
  },
  props: ['flightID', 'dialogState'],
  data: function(){
    return {
      zoom:4,
      url:'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
      satelliteURL:'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      prefix: "",
      attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      icon: L.icon({
        iconUrl: airplaneIcon,
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      origin: {},
      destination: {},
      flightData: null,
      flightDataErr: null,
      flight: null,
      ofpDoc: null,
      flightPlan: null,

      landing: null,
      landingLoading: true,

      aircraft: null,
      aircraftLoading: true,

      loading: true,

      show: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    generateFullRoute: function(){
      return Shared.methods.generateFullRoutePlan(this.flightPlan, this.flight)
    },
    routeLatLngs: function(){
      return this.generateFullRoute.map(item => L.latLng(item['lat'], item['long']))
    },
    routeWaypoints: function(){
      return this.generateFullRoute.map(item => item['name'])
    },
    internalDialogState: {
      get: function(){
        return this.dialogState
      },
      set: function (val) {
        this.$emit('update:dialogState', val)
      }
    },
    mapCenter: function (){
      if (this.haveValidData){
        const start = this.flightData['flight_data_arr'][0]
        let end = this.flightData['flight_data_arr']
        end = end[end.length-1]

        const midPoint = Shared.methods.midPoint(start['latitude'], start['longitude'], end['latitude'], end['longitude'])

        return L.latLng(midPoint[0], midPoint[1])
      }else if (this.flightPlan !== null){
        const lat =  this.flightPlan['origin_lat']
        const long = this.flightPlan['origin_long']
        return L.latLng(lat, long)
      }
      return L.latLng(0,0)
    },
    haveValidData: function(){
      return this.flightData !== null && this.flightData['flight_data_arr'] !== null
    },
    latlngs: function (){
      if (!this.haveValidData){
        return []
      }
      return this.flightData["flight_data_arr"].map(run => [run['latitude'], run['longitude']])
    },
    flightDistance: function(){
      if (this.haveValidData){
        const startLat = this.flightData['flight_data_arr'][0]['latitude']
        const startLon = this.flightData['flight_data_arr'][0]['longitude']
        const endLat = this.flightData['flight_data_arr'][this.flightData['flight_data_arr'].length-1]['latitude']
        const endLon = this.flightData['flight_data_arr'][this.flightData['flight_data_arr'].length-1]['longitude']

        return this.calcDistance(startLat, startLon, endLat, endLon).toFixed()
      }else{
        return 0
      }
    },
    landingLocations: function(){
      if (this.landing.length === 0){
        return [this.latLong(0, 0)]
      }
      return this.landing.map(d => this.latLong(d['landing']['report']['latitude'], d['landing']['report']['longitude']))
    },
    thresholdMarker: function(){
      if (this.landing.length === 0){
        return [this.latLong(0, 0)]
      }
      return this.landing.map(d => this.latLong(this.destination['latitude'], this.destination['longitude']))
    }
  },
  watch: {
    internalDialogState: function (val){
      if (val){
        this.open()
      }else{
        this.close()
      }
    }
  },
  methods: {
    close: function(){
      this.origin = {}
      this.destination = {}
      this.flightData = null
      this.flightDataErr = null
      this.flight = null
      this.ofpDoc = null
      this.flightPlan = null
      this.loading = true

      this.aircraft = null
      this.aircraftLoading = true

      this.landing = null
      this.landingLoading = true
    },
    open: function(){
      this.getFlightData(this.flightID)
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(this.$baseURL + "/getFlightPlan?flightID="+this.flightID, requestOptions)
        .then(response => response.json())
        .then(data => {
          this.flightPlan = data['result']
        })
      fetch(this.$baseURL + "/" + this.currentUser['user'] + "/flightOFP?flightID="+this.flightID, requestOptions)
        .then(response => response.json())
        .then(data => {
          const error = data['error']
          const result = data['result']
          if (error != null && result != null) {
            this.ofpDoc = result['OFP']
          }
        })
      fetch(this.$baseURL+"/"+this.currentUser['user']+"/getSingleFlight?flightID="+this.flightID, requestOptions)
        .then(response => {
          if (response.ok){
            return response.json().then(result => {
              if (!('origin_rwy' in result) || !('destination_rwy' in result)){
                // SKIP
              }else {
                const originAirport = result['origin']
                const destinationAirport = result['destination']

                const originRwy = result['origin_rwy']
                const destinationRwy = result['destination_rwy']

                if (originRwy !== "" && destinationRwy !== "") {
                  fetch(this.$baseURL + "/runway?airport=" + originAirport + "&runway=RW" + originRwy)
                    .then(response => {
                      if (response.ok) {
                        response.json().then(data => {
                          this.origin = data
                        })
                      }
                    })

                  fetch(this.$baseURL + "/runway?airport=" + destinationAirport + "&runway=RW" + destinationRwy)
                    .then(response => {
                      if (response.ok) {
                        response.json().then(data => {
                          this.destination = data
                        })
                      }
                    })
                }

                this.flight = result

                fetch(this.$baseURL + "/getAircraft?aircraftID="+this.flight['aircraft_id'])
                  .then(response => {
                    if (response.ok){
                      response.json().then(data => {
                        if (data !== null ) {
                          this.aircraft = data
                        }
                        this.aircraftLoading = false
                      })
                    }else{
                      this.aircraftLoading = false
                    }
                  })

                fetch(this.$baseURL + "/landingData?flightID=" + this.flight['id'])
                  .then(response => response.json())
                  .then(data => {
                    if (data['result'] !== null){
                      this.landing = data['result']
                    }
                    this.landingLoading = false
                  })

              }
            })
          }
          console.error(response.status)
          return null
        })
    },
    isEmpty: function(obj){
      if (obj === null){
        return false
      }
      return Object.keys(obj).length === 0
    },
    getFlightData: function(flightID){
      if (flightID !== this.currentDisplayedFlightID){
        this.flightData = null
        this.currentDisplayedFlightID = flightID
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(this.$baseURL+"/"+this.currentUser['user']+"/userFlightData?flightID="+flightID, requestOptions)
        .then(response => {
          if (response.ok){
            return response.json().then(data => {
              this.flightData = data
              this.loading = false
            })
          }
          console.error(response.status)
          this.flightDataErr = "error"
          this.loading = false
        })
    },
    calcDistance: function (lat1, lon1, lat2, lon2){

      function toRad(Value)
      {
        return Value * Math.PI / 180;
      }

      const R = 3440; // nautical miles
      const dLat = toRad(lat2-lat1);
      const dLon = toRad(lon2-lon1);
      lat1 = toRad(lat1);
      lat2 = toRad(lat2);

      const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      return  R * c;
    },
    msToTime: function(duration) {
      if (duration === 0){
        return "UNKNOWN"
      }

      let seconds = duration / 1000000000
      let h = Math.floor(seconds / 3600);
      let m = Math.floor(seconds % 3600 / 60);
      let s = Math.floor(seconds % 3600 % 60);

      if (h <= 9) {
        h = "0" + h
      }
      if (m <= 9) {
        m = "0" + m
      }
      if (s <= 9) {
        s = "0" + s
      }
      return h + ":" + m + ":" + s;
    },
    openShare: function(flightID){
      let page = this.$router.resolve({name: "FlightShare", params: {flightID: flightID}})
      window.open(page.href, "_blank")
    },
    downloadCSV: function(flightID){
      window.open(this.$baseURL + "/" + this.currentUser['user'] + "/userFlightData?flightID=" + flightID + "&format=csv")
    },
    copyShare: function(flightID){
      let page = this.$router.resolve({name: "FlightShare", params: {flightID: flightID}})
      navigator.clipboard.writeText( location.origin + page.href)

      this.show = true

      setTimeout(function (){this.show = false}.bind(this), 1000)

      return true
    },
    latLong: function(lat, long){
      return L.latLng(lat, long)
    },
    radians_to_degrees: function(radians)
    {
      let pi = Math.PI;
      return radians * (180/pi);
    },
  }
}
</script>

<style lang="css" scoped>
.data-row{
  padding-left:15px;
  padding-right: 15px;
  margin-left:-15px;
  margin-right:-12px;
  display: flex;
}

.data-row p{
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  margin-left:15px;
  margin-right: 15px;
}

.alignedItems {
  margin-bottom: 0 !important;
}

.alignedItems span:first-child{
  text-align: left;
}

.alignedItems span:last-child{
  text-align: right;
  flex-grow: 1;
}
</style>
