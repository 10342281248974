<template>
  <v-dialog v-model="internalDialogState" style="z-index:2000000000000000000000;" width="700">
    <v-card>
      <v-card-title class="headline">
        TAF Report
      </v-card-title>
      <v-card-text>
        <h4>Time</h4>
        <p>{{tafData['issue_time']}}</p>
        <h4>Raw Response</h4>
        <p>{{tafData['raw_text']}}</p>
        <h4>Forecasts:</h4>
        <div v-for="forecast in tafData['forecast']" style="padding: 1rem">
          <h4>Wind</h4>
          <p>{{forecast['wind_dir_degrees']}}@{{forecast['wind_speed_knots']}}</p>
          <h4>Visibility</h4>
          <p>{{forecast['visibility_statute_miles']}} miles</p>
        </div>
      </v-card-text>
      <v-card-actions style="display: flex; justify-content: flex-end;">
        <v-btn color="error" v-on:click="internalDialogState = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TafModal",
  props: ['dialogState', 'tafData'],
  computed: {
    internalDialogState: {
      get: function(){
        return this.dialogState
      },
      set: function(val){
        this.$emit('update:dialogState', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
