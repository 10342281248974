<template>
  <v-dialog v-model="internalDialogState" width="500">
    <v-card>
      <v-card-title class="headline">
        Confirmation
      </v-card-title>
      <v-card-text>
        Are you sure? This action cannot be undone
      </v-card-text>
      <v-card-actions style="justify-content: center">
        <v-btn color="error" v-on:click="clickYes()">Yes</v-btn>
        <v-btn color="success" v-on:click="clickNo()">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: ['dialogState'],
  data: function (){
    return {
      internalDialogState: false,
      resolve: null
    }
  },
  methods: {
    run: function (){
      this.internalDialogState = true
      return new Promise(function (resolve, reject){
        this.resolve = resolve
      }.bind(this))
    },
    clickYes(){
      this.internalDialogState = false
      this.resolve(true)
    },
    clickNo(){
      this.internalDialogState = false
      this.resolve(false)
    }
  }
}
</script>

<style scoped>

</style>
