<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Landing Reports
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details/>
          </v-card-title>
          <v-data-table :items="landingData" :headers="headers" :search="search" :loading="loading" :items-per-page="15">
            <template #item.title="{ item }">
              <span v-if="item.report['atcid'] && item.report['atcid'].replace(/\t/g, '').replace(/\s/g, '') !== '' && item.report['atcid'].length > 2">{{ item.report['atcid'] }}</span>
              <span v-else-if="item.report['atc_flight_number']">{{ item.report['atc_flight_number'] }}</span>
              <span v-else>{{item.title}}</span>
            </template>
            <template #item.vertical_speed_aircraft="{ item }">
              <p>
                <span v-if="'vertical_speed_touchdown_value' in item['report']">
                  -{{ (item['report']['vertical_speed_touchdown_value'] * 60).toFixed(2)}}
                </span>
                <span v-else>
                  {{ (item.report['vertical_speed_aircraft'] * 60).toFixed(2)}}
                </span>
              </p>
            </template>
            <template #item.g_force="{ item }">
              <p>{{item['report']['g_force'].toFixed(2)}} G</p>
            </template>
            <template #item.touchdown_speed="{ item }">
              <p>{{item['report']['ground_speed'].toFixed(0)}} Kts</p>
            </template>
            <template #item.crosswind="{ item }">
              <p>{{Math.abs(item['report']['cross_wind']).toFixed(2)}} Kts</p>
            </template>
            <template #item.airport="{ item }">
              <p v-if="item.airport !== ''">{{item.airport}}</p>
              <p v-else>UNKNOWN</p>
            </template>
            <template #item.runway="{ item }">
              <p v-if="item['runway'] !== ''">{{item['runway']}}</p>
              <p v-else>UNKNOWN</p>
            </template>
            <template #item.actions="{ item }">
              <div class="d-flex">
                <v-btn color="success" v-on:click="openLandingModal(item)" style="margin-right: 5px">View</v-btn>
                <v-btn color="error" v-on:click="deleteLandingWithPopup(item.id)" style="margin-right: 5px">Delete</v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmDialog ref="confirmDialogRef"/>
    <LandingModal :flightID="flightID" :landingID="landingID" :passedInLanding="landing" :dialog-state.sync="dialogState"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LandingModal from "@/components/LandingModal";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "LandingLogList",
  components: {
    LandingModal,
    ConfirmDialog
  },
  data: function(){
    return {
      search: "",
      headers: [
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'Vertical Speed (fpm)',
          value: 'vertical_speed_aircraft',
          align: 'center'
        },
        {
          text: 'G Force',
          value: 'g_force',
          align: 'center'
        },
        {
          text: 'Touchdown Speed',
          value: 'touchdown_speed',
          align: 'center'
        },
        {
          text: 'Crosswind',
          value: 'crosswind',
        },
        {
          text: "Airport",
          value: "airport",
        },
        {
          text: "Runway",
          value: "runway",
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'end',
          width: '1%'
        }
      ],
      landingData: [],
      loading: true,
      dialogState: false,

      flightID: null,
      landingID: null,
      landing: null
    }
  },
  created: function(){
    this.getLandingData()
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser'
    })
  },
  methods: {
    openLandingModal: function(item){
      this.flightID = null
      this.landingID = null
      this.landing = null

      this.flightID = item["flight_id"]
      this.landingID = item["id"]
      this.landing = item
      this.dialogState = true
    },
    getLandingData: function(){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(this.$baseURL+"/"+this.currentUser['user']+"/landingData", requestOptions)
        .then(response => response.json())
        .then(function (data){
          if (data['result'] != null) {
            this.landingData = data['result']
          }
          this.loading = false
        }.bind(this))
    },
    deleteLandingWithPopup: function (landingID){
      this.$refs.confirmDialogRef.run().then(function (confirmRes){
        if (confirmRes){
          this.deleteLanding(landingID)
        }
      }.bind(this))
    },
    deleteLanding: function(landingID){
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(this.$baseURL+"/"+this.currentUser['user']+"/deleteLanding?landingID="+landingID, requestOptions)
        .then(response => response.json())
        .then(() => {
          this.landingData = this.landingData.filter(landing => landing.id !== landingID)
        })
    }
  }
}
</script>

<style scoped>

</style>
