<template>
  <div>
    <v-row>
      <v-col md="7" cols="12">
        <v-card>
          <v-card-title>
            All Tracked Flights
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details/>
          </v-card-title>
          <v-data-table :items="flights" :headers="headers" :search="search" :items-per-page="15" :item-class="rowClass" ref="flightLogDataTable" @pagination="processMapFilter">
            <template #item.start_time="{ item }">
              <p>{{formatDate(item['start_time'])}}</p>
            </template>
            <template #item.in_flight_length="{ item }">
              <p>{{msToTime(item['in_flight_length'])}}</p>
            </template>
            <template #item.end_time="{ item }">
              <p>{{formatDate(item['end_time'])}}</p>
            </template>
            <template #item.actions="{ item }">
              <div class="d-flex">
                <v-btn color="success" v-on:click="flightID = item.id;dialogState = true"  style="margin-right: 5px">View</v-btn>
                <v-btn v-if="!item['deleted'] && !item['in_flight']" color="warning" v-on:click="deleteFlightDataWithPopup(item.id);"  style="margin-right: 5px">Delete Data</v-btn>
                <v-btn v-if="!item['in_flight']" color="error" v-on:click="deleteFlightWithPopup(item.id)">Delete</v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col md="5" cols="12" class="d-flex flex-column">
        <v-row class="flex-grow-0">
          <v-col class="text-center">
            <p>Flight Count</p>
            <h2>{{flightCount}}</h2>
          </v-col>
          <v-col class="text-center">
            <p>Airports Visited</p>
            <h2>{{airportsVisited}}</h2>
          </v-col>
          <v-col class="text-center">
            <p>Flight Hours</p>
            <h2>{{msToHours(totalFlightTime)}}</h2>
          </v-col>
          <v-col class="text-center">
            <p>Distance Travelled</p>
            <h2>{{totalDistanceString}} nm</h2>
          </v-col>
        </v-row>
        <v-row class="flex-grow-1" style="min-height:40vh;">
          <l-map ref="map" :zoom.sync="zoom" :center="mapCenter" style="z-index: 0;" :options="{worldCopyJump: true, preferCanvas:true}">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-circle-marker v-for="icaoData in airportMarkersStandard" :lat-lng="icaoData['lat-lng']" :radius="1">
              <l-tooltip>{{icaoData['icao']}}</l-tooltip>
            </l-circle-marker>
            <l-circle-marker v-for="icaoData in airportMarkersZoom" :lat-lng="icaoData['lat-lng']" :radius="8">
              <l-tooltip :options="{'permanent': true}">{{icaoData['icao']}}</l-tooltip>
            </l-circle-marker>
          </l-map>
        </v-row>
        <v-row class="flex-grow-0" v-if="mostVisitedAirports.length > 0">
          <v-col>
            <h2 class="text-center">Most Visited Airports</h2>
          </v-col>
        </v-row>
        <v-row class="flex-grow-0">
          <v-col class="text-center pa-0" v-for="airport in mostVisitedAirports">
            <p class="ma-0">{{airport["icao"]}}</p>
            <h2>{{airport["count"]}}</h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ConfirmDialog ref="confirmDialogRef"/>
    <FlightModal :flight-data="flightData" :flightID="flightID" :dialog-state.sync="dialogState"/>
  </div>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog";
import FlightModal from "@/components/FlightModal";
import {mapGetters} from "vuex";
import {
  LCircleMarker,
  LControlAttribution,
  LIcon,
  LMap,
  LMarker,
  LPolyline,
  LPopup,
  LTileLayer,
  LTooltip
} from "vue2-leaflet";
import "leaflet.geodesic"
import Shared from "@/components/Shared";

let baseURL = ''
if (process.env.NODE_ENV === 'production'){
  baseURL = new URL(window.location).origin
}else{
  baseURL = 'http://localhost:8080'
}

export default {
  name: "FlightLogList",
  data: function(){
    return {
      headers: [
        {
          text: 'Flight Number',
          value: 'flight_number'
        },
        {
          text: 'Origin',
          value: 'origin'
        },
        {
          text: 'Start Flight',
          value: 'start_time'
        },
        {
          text: 'Destination',
          value: 'destination'
        },
        {
          text: 'In Flight Length',
          value: 'in_flight_length'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          width: '1%'
        }
      ],
      flightID: null,
      dialogState: false,
      flightData: null,
      currentDisplayedFlightID: null,
      currentLines: [],

      totalDistance: 0,
      airportData: {},

      uniqueAirports: {},
      tripICAOs: [],

      currentlyShowedAirports: [],

      initialStatLoad: true,

      currentlyShowingAirportMarkers: false,

      mapCenter: L.latLng(0,0),
      attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom:2,
      url:'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
      prefix: "",
      icon: L.icon({
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        iconUrl: 'icon.png',
      }),

      search: ""
    }
  },
  components: {
    LCircleMarker,
    ConfirmDialog,
    FlightModal,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LControlAttribution,
    LPolyline,
    LIcon,
  },
  props: ['flightsProp'],
  mounted: function(){
  },
  created: function(){
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    flights: {
      get: function(){
        return this.flightsProp.sort(function (a, b) {
          return new Date(b['start_time']) - new Date(a['start_time'])
        })
      },
      set: function (val) {
        this.$emit('update:flightsProp', val)
      }
    },
    totalDistanceString: function(){
      return Math.round(this.totalDistance).toLocaleString()
    },
    totalFlightTime: function(){
      return this.flights.map(flight => flight['in_flight_length']).reduce((a, b) => a + b, 0)
    },
    flightCount: function(){
      return this.flights.length
    },
    airportsVisited: function(){
      return Object.keys(this.uniqueAirports).length
    },
    mostVisitedAirports: function(){
      return Object.entries(this.uniqueAirports).map((e) => {
        return {"icao": e[0], "count": e[1]}
      }).sort((a, b) => {
        return b["count"] - a["count"]
      }).slice(0,4)
    },
    airportMarkersZoom: function(){
      if (this.zoom > 4){
        return this.tripICAOs
      }
      return []
    },
    airportMarkersStandard: function(){
      if (this.zoom <= 4){
        return this.tripICAOs
      }
      return []
    }
  },
  watch: {
    search: function(val){
    }
  },
  methods: {
    getAirportData: function(airport_array){
      return fetch(this.$baseURL + "/airport?airport=" + airport_array.join(","))
        .then((response) => response.json())
    },
    deleteFlightData: function(flightID){
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        }
      }
      fetch(baseURL+ "/" + this.currentUser['user'] + "/deleteFlightData?flightID="+flightID, requestOptions)
        .then(response => response.json())
        .then(function (data){
          if (data['error'] === ""){
            for (let i = 0; i < this.flights.length; i++){
              if (this.flights[i]['id'] === flightID){
                this.flights[i]['deleted'] = true
              }
            }
          }
        }.bind(this))
    },
    deleteFlight: function(flightID){
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(baseURL+"/" + this.currentUser['user'] +"/deleteFlight?flightID="+flightID, requestOptions)
        .then(response => response.json())
        .then(function (data){
          if (data['error'] === ""){
            this.flights = this.flights.filter(flight => flight['id'] !== flightID)
          }
        }.bind(this))
    },
    deleteFlightDataWithPopup: function(flightID){
      this.$refs.confirmDialogRef.run().then(function(confirmRes){
        if (confirmRes){
          this.deleteFlightData(flightID)
        }
      }.bind(this))
    },
    deleteFlightWithPopup: function (flightID){
      this.$refs.confirmDialogRef.run().then(function (confirmRes){
        if (confirmRes){
          this.deleteFlight(flightID)
        }
      }.bind(this))
    },
    msToTime: function(duration) {
      return Shared.methods.msToTime(duration)
    },
    msToHours: function(duration){
      return Shared.methods.msToHours(duration)
    },
    formatDate: function(input){
      let inDate = new Date(input)
      // Go has weird date before 1970 meaning null date is negative
      if (inDate.getTime() < 0){
        return "UNKNOWN"
      }
      return inDate.toLocaleString()
    },
    calcDistance: function (lat1In, lon1In, lat2In, lon2In){
      function toRad(Value)
      {
        return Value * Math.PI / 180;
      }

      const R = 3440; // nautical miles
      const dLat = toRad(lat2In-lat1In);
      const dLon = toRad(lon2In-lon1In);
      const lat1 = toRad(lat1In);
      const lat2 = toRad(lat2In);

      const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      return R * c;
    },
    addCurve: function(lat1, lon1, lat2, lon2){
      const mapRef = this.$refs.map.mapObject
      const p1 = new L.LatLng(lat1, lon1);
      const p2 = new L.LatLng(lat2, lon2);
      const options = {
        color: 'green',
        steps: 8,
        weight: 1,
      }
      const geodesic = new L.Geodesic([p1, p2], options).addTo(mapRef)
      this.currentLines.push(geodesic)
    },
    clearMapLines: function(){
      let mapRef = this.$refs.map

      if (mapRef === undefined){
        return
      }

      mapRef = mapRef.mapObject

      // find removals ==> look over existing, if not in val then remove
      for(let i = 0; i < this.currentLines.length; i++){
        this.currentLines[i].remove(mapRef)
      }
      this.currentLines = []
    },
    rowClass: function(){
      return "singleRow"
    },
    processMapFilter: function(){
      let filteredItems = this.flights

      const data = this.$refs.flightLogDataTable
      if (data !== undefined){
        filteredItems = Object.assign(data.$children[0].filteredItems, {})
      }


      let uniqueFilteredAirports = {}
      let requiredAirportsToDownload = []

      filteredItems.forEach(flight =>{
        const oA = flight['origin']
        const dA = flight['destination']

        if (flight['in_flight']){
          return
        }

        if (!(oA in uniqueFilteredAirports)){
          uniqueFilteredAirports[oA] = 1
          if (!(oA in this.airportData)){
            requiredAirportsToDownload.push(oA)
          }
        }

        if (!(dA in uniqueFilteredAirports)){
          uniqueFilteredAirports[dA] = 1
          if (!(dA in this.airportData)){
            requiredAirportsToDownload.push(dA)
          }
        }
      })

      let listChanged = false
      if (this.currentlyShowedAirports.length !== Object.keys(uniqueFilteredAirports).length) {
        this.currentlyShowedAirports.sort()
        let uniqueFilteredAirportKeys = Object.keys(uniqueFilteredAirports).sort()

        for (let i = 0; i < this.currentlyShowedAirports.length; i++){
          if (this.currentlyShowedAirports[i] !== uniqueFilteredAirportKeys[i]){
            listChanged = true
          }
        }
      }

      if (uniqueFilteredAirports.length === 0){
        return
      }

      const currentlyShowedAirportCount = this.currentlyShowedAirports.length
      this.currentlyShowedAirports = Object.keys(uniqueFilteredAirports)

      if (!listChanged && currentlyShowedAirportCount > 0){
        return
      }

      let uniquePairs = {}
      let parent = this
      let doWorkBasedOnAirportData = function(){
        parent.tripICAOs = []
        parent.clearMapLines()

        if (parent.initialStatLoad && parent.flights.length > 0){
          parent.initialStatLoad = false

          parent.flights.forEach(flight =>{
            const oA = parent.airportData[flight['origin']]
            const dA = parent.airportData[flight['destination']]

            if (oA === null || dA === null){
              return
            }

            if (flight['in_flight']){
              return
            }

            if (!(oA in parent.uniqueAirports)){
              parent.uniqueAirports[oA['identifier']] = (parent.uniqueAirports[oA['identifier']] || 0) + 1
            }

            if (!(dA in parent.uniqueAirports)){
              parent.uniqueAirports[dA['identifier']] = (parent.uniqueAirports[dA['identifier']] || 0) + 1
            }

            parent.uniqueAirports = Object.assign({}, parent.uniqueAirports)
            parent.totalDistance += parent.calcDistance(oA['airport_ref_latitude'], oA['airport_ref_longitude'], dA['airport_ref_latitude'], dA['airport_ref_longitude'])
          })
        }

        filteredItems.forEach(flight =>{
          // Do this bit for all flights
          const oA = parent.airportData[flight['origin']]
          const dA = parent.airportData[flight['destination']]

          if (oA === null || dA === null){
            return
          }

          if (flight['in_flight']){
            return
          }

          const orderedPair = [oA['identifier'], dA['identifier']].sort((a, b) => a.localeCompare(b))
          if (!(orderedPair in uniquePairs)) {
            parent.addCurve(oA['airport_ref_latitude'], oA['airport_ref_longitude'], dA['airport_ref_latitude'], dA['airport_ref_longitude'])
            uniquePairs[orderedPair] = 1
          }
        })

        for (const airportICAO in uniqueFilteredAirports){
          const airport = parent.airportData[airportICAO]

          if (airport === null){
            continue
          }

          parent.tripICAOs.push({
            'lat-lng': [airport['airport_ref_latitude'], airport['airport_ref_longitude']],
            'icao': airport['identifier']
          })
        }
      }

      if (requiredAirportsToDownload.length > 0) {
        this.getAirportData(requiredAirportsToDownload).then(d => {
          for (const airportICAO in d) {
            this.airportData[airportICAO] = d[airportICAO]
          }

          doWorkBasedOnAirportData()
        })
      }else{
        doWorkBasedOnAirportData()
      }
    }
  }
}
</script>

<style>
  .iconClass{
    background-color: red;
    width: auto !important;
    height: auto !important;
    border-radius: 8px;
    padding: 2px;
  }

  .singleRow{
    white-space: nowrap;
  }
</style>
