<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card>
          <v-card-title>
            Schedule
            <v-spacer></v-spacer>
            <v-btn v-on:click="internalDialogState = true" color="success">Add Flight</v-btn>
          </v-card-title>
          <v-data-table :items="flightSchedules" :headers="headers" :item-class="rowClass" :sort-by="['airline.name']" :items-per-page="15" :loading="loading">
            <template #item.aircraft_label="{ item }">
              <p>{{item.aircraft.registration}}/{{item.airframe.icao}}</p>
            </template>
            <template #item.actions="{ item }">
              <div class="d-flex">
                <v-btn v-on:click="dispatch(item.id)" color="primary" style="margin-right: 5px">Dispatch</v-btn>
                <v-btn v-on:click="deleteFlightSchedule(item.id)" color="error">Delete</v-btn>
              </div>
            </template>
            <template #item.eta="{ item }">
              <p>{{eta(item.origin, item.destination)}}</p>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <ScheduleModal :dialog-state.sync="internalDialogState" :schedules.sync="flightSchedules" :schedule-data.sync="scheduleData" :fleet="fleet" :airports="airports"/>
  </div>
</template>

<script>
import ScheduleModal from "./ScheduleModal";
import {mapGetters} from "vuex";
import Shared from "./Shared";
export default {
  name: "FlightSchedules",
  props: ['tab', 'dispatchData', 'dialogState', 'scheduleData', 'fleet', 'airports'],
  components: {ScheduleModal},
  data: function(){
    return {
      headers: [
        {
          text: 'Airline',
          value: 'airline.name'
        },
        {
          text: 'Flight Number',
          value: 'flight_number'
        },
        {
          text: 'Aircraft',
          value: 'aircraft_label'
        },
        {
          text: 'Origin',
          value: 'origin'
        },
        {
          text: 'Destination',
          value: 'destination'
        },
        {
          text: 'ETA',
          value: 'eta'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'end',
          width: '1%'
        }
      ],
      flightSchedules: [],

      loading: true,
    }
  },
  created: function(){
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + this.currentUser['tokenHash']
      },
    }

    fetch(this.$baseURL + "/" + this.currentUser['user'] + "/getFlightSchedule", requestOptions)
      .then(response => response.json())
      .then(function (data){
        if (data['error'] === "" && data['result'] != null){
          this.flightSchedules = data['result']
        }
        this.loading = false
      }.bind(this))
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser'
    }),
    internalDialogState:{
      get: function(){
        return this.dialogState
      },
      set: function(val){
        this.$emit('update:dialogState', val)
      }
    }
  },
  methods: {
    deleteFlightSchedule: function(scheduleID){
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }

      fetch(this.$baseURL + "/" + this.currentUser['user'] + "/deleteFlightSchedule?id="+scheduleID, requestOptions)
        .then(response => response.json())
        .then(function(data){
          this.flightSchedules = this.flightSchedules.filter(d => d.id !== scheduleID)
        }.bind(this))
    },
    dispatch: function(scheduleID){
      const schedule = this.flightSchedules.filter(d => d.id === scheduleID)[0]
      this.$emit('update:tab', 0)
      this.$emit('update:dispatchData', Object.assign({},
        {
          'origin': schedule.origin,
          'destination': schedule.destination,
          'flight_number': schedule.flight_number,
          'aircraft_id': schedule.aircraft.id,
          'airline': schedule.airline
        }))
      if (schedule['clear_on_dispatch']){
        this.deleteFlightSchedule(scheduleID)
      }
    },
    eta: function(originICAO, destinationICAO){
      return Shared.methods.calcETA(this.airports, originICAO, destinationICAO)
    },
    rowClass: function(item){
      return "singleRow"
    }
  }
}
</script>

<style scoped>

</style>

<style>
  .singleRow{
    white-space: nowrap;
  }
</style>
