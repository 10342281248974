<template>
  <v-dialog v-model="internalDialogState" width="700">
    <v-card>
      <v-card-title class="headline">
        Add Flight
      </v-card-title>
      <v-card-text>
        <v-form ref="addScheduleForm">
          <v-autocomplete label="Origin" v-model="origin" :items="airportAutoCompleteItems" :rules="requiredFieldRule(origin)" required/>
          <v-autocomplete label="Destination" v-model="destination" :items="airportAutoCompleteItems" :rules="requiredFieldRule(destination)" required/>
          <v-autocomplete label="Aircraft" v-model="aircraftID" :items="fleetWithAirline" item-text="name" item-value="id" :rules="requiredFieldRule(aircraftID)" required/>
          <v-text-field label="Flight Number (Omit IATA / ICAO)" v-model="flightNumber" :rules="requiredFieldRule(flightNumber)" required/>
          <h3>ETA: {{eta}}</h3>
          <v-switch v-model="clearOnDispatch" label="Clear on dispatch"/>
        </v-form>
      </v-card-text>
      <v-card-actions class="actionsContainer">
        <v-btn color="success" v-on:click="addFlightSchedule(origin, destination, flightNumber, aircraftID, clearOnDispatch)" :loading="saving">Add Flight</v-btn>
        <v-btn color="error" v-on:click="internalDialogState = false" :disabled="saving">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import Shared from "@/components/Shared";

export default {
  name: "ScheduleModal",
  props: ["dialogState", "schedules", 'scheduleData', 'fleet', 'airports'],
  created: function(){
    fetch(this.$baseURL + "/airlines?username="+ this.currentUser['user'])
      .then(response => {
        if (response.ok){
          response.json().then(data => {
            this.airlines = data
          })
        }
      })
  },
  data: function(){
    return {
      airlines: [],
      origin: "",
      destination: "",
      flightNumber: "",
      aircraftID: "",
      clearOnDispatch: false,
      saving: false
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser'
    }),
    internalDialogState: {
      get: function(){
        return this.dialogState
      },
      set: function(val){
        this.$emit('update:dialogState', val)
      }
    },
    airportAutoCompleteItems: function(){
      return this.airports.map(a => {
        return {text: a.identifier + " - " + a.name, value: a.identifier}
      }).sort(function (a, b){
        const textA = a["text"]
        const textB = b["text"]
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      })
    },
    fleetWithAirline: function(){
      const fleetItems = this.fleet
      let items = []

      fleetItems.forEach(item => {
        items.push({
          'id': item['aircraft']['id'],
          'name': item['airframe']['icao'] + " - " + item['aircraft']['registration'] + " - " + item['airline']['name']
        })
      })

      return items
    },
    eta: function(){
      if (this.origin === "" || this.destination === ""){
        return 0
      }
      return Shared.methods.calcETA(this.airports, this.origin, this.destination)
    }
  },
  watch: {
    internalDialogState: function(val){
      if (!val){
        this.clearDispatchItems()
        this.$refs.addScheduleForm.resetValidation()
        this.saving = false
      }
    },
    scheduleData: function(val){
      this.origin = val['origin']
      this.destination = val['destination']
      this.flightNumber = val['flight_number']
    }
  },
  methods: {
    requiredFieldRule: function(element){
      return Shared.methods.requiredFieldRule(element)
    },
    addFlightSchedule: function(origin, destination, flightNumber, aircraftID, clearOnDispatch){
      const valid = this.$refs.addScheduleForm.validate()
      if (!valid){
        return
      }

      this.saving = true

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }

      fetch(this.$baseURL + "/" + this.currentUser['user'] + "/addFlightSchedule?origin="+origin+"&destination="+destination+"&flightNumber="+flightNumber+"&aircraftID="+aircraftID+"&clearOnDispatch="+clearOnDispatch, requestOptions)
        .then(response => response.json().then(data => {
          if (!response.ok){
            console.error(data)
            return
          }
          let updateSchedules = this.schedules

          const registration = this.fleet.filter(data => {
            return data['aircraft']['id'] === aircraftID
          })[0]

          updateSchedules.push({
            id: data['result'],
            origin: origin,
            destination: destination,
            flight_number: flightNumber,
            aircraft: registration['aircraft'],
            airframe: registration['airframe'],
            airline: registration['airline'],
            clear_on_dispatch: clearOnDispatch
          })
          this.$emit('update:schedules', updateSchedules)
          this.internalDialogState = false
        }))
    },
    clearDispatchItems: function (){
      this.origin = ""
      this.destination = ""
      this.flightNumber = ""
      this.aircraftID = ""
      this.clearOnDispatch = false
    }
  }
}
</script>

<style scoped>
.actionsContainer{
  display: flex;
  justify-content: flex-end;
}
</style>
