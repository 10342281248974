<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card>
          <v-card-title>
            Airline Routes (WIP)
          </v-card-title>
          <v-container>
            <v-row>
              <v-col cols="8">
                <v-autocomplete v-model="airlineICAO" label="Airline Name" :items="airlines" item-text="name" item-value="icao" clearable></v-autocomplete>
              </v-col>
              <v-col md="1" cols="4" class="d-flex align-center justify-center">
                <v-btn v-on:click="loadData()" :disabled="loading">Search</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card style="margin-top:1rem;">
          <v-row>
            <v-col md="7" cols="12">
              <v-data-table :headers="headers" :items="flights" :loading="loading">
                <template #item.eta_minutes="{ item }">
                  {{etaMinutes(item.eta_minutes)}}
                </template>
                <template #item.actions="{ item }">
                  <div class="d-flex">
                    <v-btn v-on:click="dispatch(item.id)" color="primary" style="margin-right: 5px">Dispatch</v-btn>
                    <v-btn v-on:click="schedule(item.id)" color="warning">Schedule</v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-col>
            <v-col md="5" cols="12" class="d-flex flex-column">
              <l-map ref="airlineRouteMap" style="min-height: 30rem;" :zoom.sync="zoom" :center="mapCenter" :options="{worldCopyJump: true, preferCanvas:true}">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-circle-marker v-for="icaoData in airportICAOs" :lat-lng="icaoData['lat-lng']" :radius="1">
                  <l-tooltip>{{icaoData['icao']}}</l-tooltip>
                </l-circle-marker>
              </l-map>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import {
  LCircleMarker,
  LControlAttribution,
  LIcon,
  LMap,
  LMarker,
  LPolyline,
  LPopup,
  LTileLayer,
  LTooltip
} from "vue2-leaflet";
import "leaflet.geodesic"
import ConfirmDialog from "@/components/ConfirmDialog";
import FlightModal from "@/components/FlightModal";
import Shared from "@/components/Shared";

export default {
  name: "AirlineRoutes",
  props: ['tab', 'dispatchTab', 'dispatchData', 'addFlightSchedule', 'scheduleData', 'airports'],
  mounted: function (){
    this.getAirlines()
  },
  components: {
    LCircleMarker,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LControlAttribution,
    LPolyline,
    LIcon,
  },
  watch: {
  },
  data: function(){
    return {
      airlines: [],
      airlineICAO: undefined,

      flights: [],

      mapCenter: L.latLng(0,0),
      attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom:2,
      url:'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',

      height: 0,

      currentLines: [],
      airportICAOs: [],

      loading: false,
      headers: [
        {
          text: 'Airline',
          value: 'airline_name'
        },
        {
          text: 'Flight Number',
          value: 'flight_number'
        },
        {
          text: 'Origin',
          value: 'icao_origin'
        },
        {
          text: 'Destination',
          value: 'icao_destination'
        },
        {
          text: 'Estimated',
          value: 'eta_minutes'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'end',
          width: '1%'
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    airportsDict: function(){
      let airports = this.airports
      let airportsDict = {}
      for (let i = 0; i < airports.length; i++){
        let airport = airports[i]
        airportsDict[airport['identifier']] = airport
      }

      return airportsDict
    },
    airlinesDict: function(){
      let airlines = this.airlines
      let airlinesDict = {}
      for (let i = 0; i < airlines.length; i++){
        let airline = airlines[i]
        airlinesDict[airline['icao']] = airline
      }
      return airlinesDict
    },
    uniqueRoutes: function (){
      let flightRoutes = this.flights
      let existingRoutes = {}

      let uniqueRoutes = []

      for(let i = 0; i < flightRoutes.length; i++){
        let route = flightRoutes[i]
        const key = route['airline_icao'] + route['icao_origin'] + route['icao_destination']
        if (!(key in existingRoutes) && route['icao_origin'] !== route['icao_destination']){
          existingRoutes[key] = null
          uniqueRoutes.push(flightRoutes[i])
        }
      }

      return uniqueRoutes
    },
    uniqueAirports: function(){
      let flightRoutes = this.flights
      let airportICAOs = {}

      for (let i = 0; i < flightRoutes.length; i++){
        let route = flightRoutes[i]
        if (!(route['icao_origin'] in airportICAOs)){
          airportICAOs[route['icao_origin']] = null
        }

        if (!(route['icao_destination'] in airportICAOs)){
          airportICAOs[route['icao_destination']] = null
        }
      }

      return airportICAOs
    }
  },
  methods: {
    etaMinutes: function(minutes){
      return Shared.methods.formatMinutesToTime(minutes)
    },
    getAirlines: function(){
      axios.get(
        this.$baseURL + "/airlines?username="+ this.currentUser['user']
      ).then((response) => {
        if (response.status === 200){
          this.airlines = response.data
        }
      })
    },
    addCurve: function(lat1, lon1, lat2, lon2){
      const mapRef = this.$refs.airlineRouteMap.mapObject
      const p1 = new L.LatLng(lat1, lon1);
      const p2 = new L.LatLng(lat2, lon2);
      const options = {
        color: 'green',
        steps: 8,
        weight: 1,
      }
      const geodesic = new L.Geodesic([p1, p2], options).addTo(mapRef)
      this.currentLines.push(geodesic)
    },
    addMapRoutes: function(){
      let routes = this.uniqueRoutes

      for(let i = 0; i < routes.length; i++) {
        let route = routes[i]

        let originAirport = this.airportsDict[route['icao_origin']]
        let destinationAirport = this.airportsDict[route['icao_destination']]

        if (originAirport === undefined || destinationAirport === undefined) {
          continue
        }

        let oLat = originAirport['airport_ref_latitude']
        let oLon = originAirport['airport_ref_longitude']
        let dLat = destinationAirport['airport_ref_latitude']
        let dLon = destinationAirport['airport_ref_longitude']

        this.addCurve(oLat, oLon, dLat, dLon)
      }

      let uniqueAirports = this.uniqueAirports

      for (const airportICAO in uniqueAirports) {
        const airport = this.airportsDict[airportICAO]
        if (airport === undefined){
          continue
        }
        this.airportICAOs.push({
          'lat-lng': [airport['airport_ref_latitude'], airport['airport_ref_longitude']],
          'icao': airport['identifier']
        })
      }
    },
    dispatch: function(ID) {
      const flight = this.flights.filter(d => d.id === ID)[0]

      this.$emit('update:dispatchTab', 0)
      this.$emit('update:dispatchData', Object.assign({},
        {
          'origin': flight['icao_origin'],
          'destination': flight['icao_destination'],
          'flight_number': flight['flight_number'],
          'airline': {
            'icao': flight['airline_icao'],
            'iata': this.airlineIATAFromICAO(flight['airline_icao'])
          }
        }))
    },
    schedule: function(ID){
      const flight = this.flights.filter(d => d.id === ID)[0]
      this.$emit('update:tab', 0)
      this.$emit('update:addFlightSchedule', true)
      this.$emit('update:scheduleData', Object.assign({}, {
        "origin": flight['icao_origin'],
        "destination": flight['icao_destination'],
        "flight_number": flight['flight_number'],
      }))
    },
    airlineIATAFromICAO: function(icao){
      return this.airlinesDict[icao]['iata']
    },
    loadData: function(){
      this.flights = []
      this.loading = true
      this.readDataFromAPI()
    },
    readDataFromAPI: function(){
      axios.get(
        this.$baseURL + "/airlineRoutes?airline_icao=" + this.airlineICAO
      ).then((response) => {
        if (response['data']['result'] === null){
          this.flights = []
        }else{
          this.flights = response['data']['result']
        }

        this.loading = false
        this.clearMap()
        this.addMapRoutes()
        this.resetMap()
      })
    },
    clearMap: function(){
      let mapRef = this.$refs.airlineRouteMap

      if (mapRef === undefined){
        return
      }

      mapRef = mapRef.mapObject
      for(let i = 0; i < this.currentLines.length; i++){
        this.currentLines[i].remove(mapRef)
      }
      this.currentLines = []
      this.airportICAOs = []
    },
    resetMap: function(){
      setTimeout(() => {
        this.$refs.airlineRouteMap.mapObject.invalidateSize()
      }, 0);
    }
  }
}
</script>

<style scoped>

</style>
