<template>
  <div class="text-center">
    <v-dialog v-model="internalDialogState">
      <v-card>
        <v-card-title class="headline">
          Flight Data
        </v-card-title>
        <v-container v-if="landingDataLoading || landing === null">
          <v-row  style="height: 70vh;">
            <v-col style="text-align: center; align-items: center; justify-content: center; display: flex; flex-direction: column">
              <v-progress-circular
                :size="150"
                :width="5"
                color="amber"
                indeterminate
              ></v-progress-circular>
              <h1 style="margin: 0.67em">
                Loading...
              </h1>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else>
          <v-row>
            <v-col md="6" cols="12">
              <div v-if="runwayData != null">
                <v-divider/>
                <h4>Runway Data</h4>
                <v-divider/>

                <div class="data-row">
                  <p class="alignedItems">
                    <span>Runway:</span>
                    <span>{{runwayData['runway_identifier']}}</span>
                  </p>
                  <p  class="alignedItems">
                    <span>Elevation:</span>
                    <span>{{runwayData['elevation']}} ft</span>
                  </p>
                </div>

                <div class="data-row">
                  <p class="alignedItems">
                    <span>Length:</span>
                    <span>{{runwayData['length']}} ft</span>
                  </p>
                  <p class="alignedItems">
                    <span>Width:</span>
                    <span>{{runwayData['width']}} ft</span>
                  </p>
                </div>

                <div class="data-row">
                  <p class="alignedItems">
                    <span>Heading:</span>
                    <span>{{runwayData['magnetic_bearing']}} &#176;</span>
                  </p>
                  <p class="alignedItems">
                    <span>Slope:</span>
                    <span>{{runwayData['gradient']}} &#176;</span>
                  </p>
                </div>
              </div>

              <v-divider/>
              <h4>Landing Data</h4>
              <v-divider/>

              <div class="data-row">
                <p class="alignedItems">
                  <span>Heading:</span>
                  <span>{{radians_to_degrees(landing['report']['heading_mag']).toFixed(2)}} &#176;</span>
                </p>
                <p class="alignedItems">
                  <span>Bank:</span>
                  <span>{{radians_to_degrees(landing['report']['bank'] * -1).toFixed(2)}} &#176;</span>
                </p>
              </div>
              <div class="data-row">
                <p class="alignedItems">
                  <span>Pitch:</span>
                  <span>{{radians_to_degrees(landing['report']['pitch'] * -1).toFixed(2)}} &#176;</span>
                </p>
                <p class="alignedItems">
                  <span>Speed:</span>
                  <span>{{landing['report']['ground_speed'].toFixed(0)}} Kts</span>
                </p>
              </div>
              <div class="data-row">
                <p class="alignedItems">
                  <span>
                    Vertical Speed:
                  </span>
                  <span v-if="'vertical_speed_touchdown_value' in landing['report']">
                    -{{ (landing['report']['vertical_speed_touchdown_value'] * 60).toFixed(0)}} fpm
                  </span>
                  <span v-else>
                    {{ (landing['report']['vertical_speed_aircraft'] * 60).toFixed(0)}} fpm
                  </span>
                </p>
                <p class="alignedItems">
                  <span>G Force:</span>
                  <span>{{landing['report']['g_force'].toFixed(2)}} G</span>
                </p>
              </div>

              <div class="data-row" v-if="'cross_wind' in landing['report'] && 'head_wind' in landing['report']">
                <p class="alignedItems">
                  <span>
                    Crosswind:
                  </span>
                  <span>
                    {{ (landing['report']['cross_wind']).toFixed(2)}} Kts
                  </span>
                </p>
                <p class="alignedItems">
                  <span>Tailwind:</span>
                  <span>
                    {{landing['report']['head_wind'].toFixed(2)}} Kts
                  </span>
                </p>
              </div>

              <v-divider/>
              <h4>Aircraft Data</h4>
              <v-divider/>

              <div class="data-row">
                  <p class="alignedItems">
                    <span>Aircraft Title:</span>
                    <span>{{landing['report']['aircraft_title']}}</span>
                  </p>
                <p  class="alignedItems">
                    <span>Aircraft Registration:</span>
                    <span>{{landing['report']['atcid']}}</span>
                  </p>
              </div>
              <v-divider/>


              <v-row v-if="approach !== null">
                <v-col md="6" cols="12">
                  <FlightModalGraph :flight-data="{'flight_data_arr': approach}" filter-field="cross_wind" title="Cross Wind" titleType="h4"/>
                </v-col>
                <v-col md="6" cols="12">
                  <FlightModalGraph :flight-data="{'flight_data_arr': approach}" filter-field="head_wind" title="Tail Wind" titleType="h4"/>
                </v-col>
              </v-row>

            </v-col>
            <v-col md="6" cols="12" style="min-height:20rem;" class="d-flex flex-column">
              <div class="flex-grow-1" style="min-height: 30rem">
                <l-map :zoom="zoom" :center="mapCenter" style="z-index: 0;" ref="landingMap" :options="{worldCopyJump: true}">
                  <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                  <l-circle-marker v-if="thresholdMarker != null" :lat-lng="thresholdMarker" :radius="2" :color="'blue'"></l-circle-marker>
                  <l-circle-marker v-if="landingTarget != null" :lat-lng="landingTarget" :radius="2" :color="'green'"></l-circle-marker>
                  <l-circle-marker :lat-lng="mapCenter" :radius="2" :color="'red'"></l-circle-marker>
                </l-map>
              </div>
              <div>
                <v-row style="text-align: center">
                  <v-col>
                    <div class="legendContainerDiv">
                      <p>
                        <span class="legendCircleSpan" style="background-color: blue;"/>
                        Runway Threshold
                      </p>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="legendContainerDiv">
                      <p>
                        <span class="legendCircleSpan" style="background-color: green;"/>
                        Thousand foot markers
                      </p>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="legendContainerDiv">
                      <p>
                        <span class="legendCircleSpan" style="background-color: red;"/>
                        Landing Point
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>

        </v-container>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="internalDialogState = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { LMap, LTileLayer, LCircleMarker} from 'vue2-leaflet'
import {mapGetters} from "vuex";
import FlightModalGraph from "@/components/FlightModalGraph";


export default {
  name: "LandingModal",
  components: {
    LMap,
    LTileLayer,
    LCircleMarker,
    FlightModalGraph,
  },
  props: ['flightID', 'landingID', 'passedInLanding', 'dialogState'],
  data: function (){
    return {
      thresholdMarker: null,
      landingTarget: null,
      runwayData: null,

      landingData: null,
      landingDataLoading: true,

      zoom:15,
      url:'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    internalDialogState: {
      get: function(){
        return this.dialogState
      },
      set: function (val) {
        this.$emit('update:dialogState', val)
      }
    },
    mapCenter: function(){
      return L.latLng(this.landing['report']['latitude'], this.landing['report']['longitude'])
    },
    landingIdx: function(){
      if (this.landingData === null){
        return -1
      }

      for (let i = 0; i < this.landingData.length; i++){
        if (this.landingData[i]['landing']['id'] === this.landingID){
          return i
        }
      }

      return -1

    },
    landing: function(){
      const landingIdx = this.landingIdx
      if (landingIdx === -1){
        return this.passedInLanding
      }

      return this.landingData[landingIdx]['landing']
    },
    approach: function(){
      const landingIdx = this.landingIdx
      if (landingIdx === -1){
        return null
      }

      return this.landingData[landingIdx]['approach']
    }
  },
  watch: {
    internalDialogState: function(val){
      if (val){
        this.onOpen()
      }
    }
  },
  methods: {
    onOpen: function(){
      setTimeout(() => {
        if (this.$refs.landingMap !== undefined){
          this.$refs.landingMap.mapObject.invalidateSize();
        }
      },100);

      if (this.flightID !== null && this.flightID !== "") {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + this.currentUser['tokenHash']
          },
        }
        fetch(this.$baseURL + "/" + this.currentUser['user'] + "/getSingleFlight?flightID=" + this.flightID, requestOptions)
          .then(response => {
            if (response.ok) {
              return response.json().then(result => {
                if (!('destination_rwy' in result)) {
                  // SKIP
                } else {
                  const destinationAirport = result['destination']
                  const destinationRwy = result['destination_rwy']

                  fetch(this.$baseURL + "/runway?airport=" + destinationAirport + "&runway=RW" + destinationRwy)
                    .then(response => {
                      if (response.ok) {
                        response.json().then(data => {
                          const result = data
                          this.runwayData = result
                          this.thresholdMarker = L.latLng(result['latitude'], result['longitude'])
                          this.landingTarget = this.calculateLandingTarget(result['runway_true_bearing'], result['latitude'], result['longitude'])
                        })
                      }
                    })
                }
              })
            }
            console.error(response.status)
            return null
          })
      }

      fetch(this.$baseURL + "/landingData?flightID="+this.flightID)
        .then(response => response.json())
        .then(function (data){
          if (data['result'] != null) {
            this.landingData = data['result']
          }
          this.landingDataLoading = false
        }.bind(this))
    },
    onClose: function(){
      this.runwayData = null
      this.thresholdMarker = null
    },
    radians_to_degrees: function(radians)
    {
      let pi = Math.PI;
      return radians * (180/pi);
    },
    calculateLandingTarget: function(bearing, lat, lng){
      function toRad(Value)
      {
        return Value * Math.PI / 180;
      }

      if (bearing === undefined || lat === undefined || lng === undefined || bearing === null || lat === null || lng === null){
        return null
      }

      const R = 20902230.971129 // Earth radius in feet
      const radBearings = bearing / 360 * 2 * Math.PI
      const distance = 1000

      lat = toRad(lat)
      lng = toRad(lng)

      const lat1 = Math.asin(Math.sin(lat)*Math.cos(distance/R) + Math.cos(lat)*Math.sin(distance/R)*Math.cos(radBearings))
      const lng1 = lng + Math.atan2(Math.sin(radBearings)*Math.sin(distance/R)*Math.cos(lat), Math.cos(distance/R)-Math.sin(lat)*Math.sin(lat1))

      return L.latLng(this.radians_to_degrees(lat1), this.radians_to_degrees(lng1))
    }
  }
}
</script>

<style scoped>
  .data-row{
    padding-left:15px;
    padding-right: 15px;
    margin-left:-15px;
    margin-right:-12px;
    display: flex;
  }

  .data-row p{
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    margin-left:15px;
    margin-right: 15px;
  }

  .alignedItems {
    margin-bottom: 0 !important;
  }

  .alignedItems span:first-child{
    text-align: left;
  }

  .alignedItems span:last-child{
    text-align: right;
    flex-grow: 1;
  }

  .legendCircleSpan{
    border-radius: 50%;
    display: inline-block;
    height:1em;
    width:1em;
    margin-right: 0.2em;
  }

  .legendContainerDiv{
    display:flex;
    align-items: center;
    justify-content: center;
  }
</style>
