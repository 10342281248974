<template>
  <v-container>
    <v-tabs centered v-model="tab" v-if="!loading">
      <v-tab>Flight Planning</v-tab>
      <v-tab>Flight Log</v-tab>
      <v-tab>Landing Log</v-tab>
      <v-tab>Schedules</v-tab>
    </v-tabs>
    <h1 v-else>
    Loading
    </h1>
    <v-tabs-items :touchless="true" v-model="tab">
      <v-tab-item>
        <FlightPlanning :tab="tab" :flights-prop.sync="flights" :dispatchData="dispatchData" :fleet="fleet" :airports="airports"/>
      </v-tab-item>
      <v-tab-item>
        <FlightLogList :flights-prop.sync="flights"/>
      </v-tab-item>
      <v-tab-item>
        <LandingLogList/>
      </v-tab-item>
      <v-tab-item>
        <SchedulesTab :tab.sync="tab" :dispatchData.sync="dispatchData" :fleet="fleet" :airports="airports"/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import FlightModalGraph from "@/components/FlightModalGraph";
import {LControlAttribution, LIcon, LMap, LMarker, LCircleMarker, LPolyline, LPopup, LTileLayer, LTooltip} from "vue2-leaflet";
import FlightLogList from "@/components/FlightLogList";
import FlightPlanning from "@/components/FlightPlanning";
import LandingLogList from "@/components/LandingLogList";
import FlightSchedules from "../components/FlightSchedules";
import SchedulesTab from "@/components/SchedulesTab";

let baseURL = ''
if (process.env.NODE_ENV === 'production'){
  baseURL = new URL(window.location).origin
}else{
  baseURL = 'http://localhost:8080'
}

export default {
  name: "Flights",
  components: {
    SchedulesTab,
    FlightSchedules,
    LandingLogList,
    FlightPlanning,
    FlightLogList,
    FlightModalGraph,
    LMap,
    LTileLayer,
    LMarker,
    LCircleMarker,
    LPopup,
    LTooltip,
    LControlAttribution,
    LIcon,
    LPolyline
  },
  created: function() {
    this.getFlights()

    fetch(baseURL + "/airports")
      .then(response => {
        if (response.ok){
          return response.json().then(data => {
            this.airports = data
          })
        }
      })

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + this.currentUser['tokenHash']
      },
    }
    fetch(this.$baseURL + "/" + this.currentUser['user'] + "/richUserFleet", requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if ('result' in data && data['result'] != null) {
          this.fleet = data['result']
        }
      })
  },
  data: function (){
    return{
      flights: [],
      loading: true,
      tab: null,
      test: "",
      dispatchData: null,
      fleet: [],
      airports: [],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
  },
  methods: {
    getFlights: function (){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(baseURL+"/"+this.currentUser['user']+"/getFlights", requestOptions)
        .then(response => {
          if (!response.ok){
            console.error("An error occurred")
            return
          }
          return response.json()
        })
        .then(function (data){
          this.flights = data['flights']
          this.loading = false
        }.bind(this))
    },
  }
}
</script>

<style scoped>

</style>
