<template>
  <v-dialog v-model="internalDialogState" style="z-index:2000000000000000000000;" width="700">
    <v-card>
      <v-card-title class="headline">
        METAR Report
      </v-card-title>
      <v-card-text>
        <h4>Time</h4>
        <p>{{metarData['observation_time']}}</p>
        <h4>Wind</h4>
        <p>{{metarData['wind_dir_degrees']}}@{{metarData['wind_speed_knots']}}</p>
        <h4>Visibility</h4>
        <p>{{metarData['visibility_statute_miles']}} miles</p>
        <h4>Clouds</h4>
        <p v-for="conditions in metarData['sky_condition']">{{conditions['sky_cover']}} at {{conditions['cloud_base_ft_agl']}}ft</p>
        <h4>Temperature</h4>
        <p>{{metarData['temp_c']}} &#176;C</p>
        <h4>Dewpoint</h4>
        <p>{{metarData['dewpoint_c']}} &#176;C</p>
        <h4>Altimeter</h4>
        <p v-if="metarData['altim_in_hg']">{{metarData['altim_in_hg'].toFixed(2)}}</p>
        <p v-if="metarData['altim_in_hg']">{{hPaConvert}}</p>
        <h4>Raw Response</h4>
        <p>{{metarData['raw_text']}}</p>
      </v-card-text>
      <v-card-actions style="display: flex; justify-content: flex-end;">
        <v-btn color="error" v-on:click="internalDialogState = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MetarModal",
  props: ['dialogState', 'metarData'],
  computed: {
    internalDialogState: {
      get: function(){
        return this.dialogState
      },
      set: function(val){
        this.$emit('update:dialogState', val)
      }
    },
    hPaConvert: function(){
      return Math.round(1013.25 * (this.metarData['altim_in_hg'] / 29.92))
    }
  }
}
</script>

<style scoped>

</style>
