var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Landing Reports "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"items":_vm.landingData,"headers":_vm.headers,"search":_vm.search,"loading":_vm.loading,"items-per-page":15},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [(item.report['atcid'] && item.report['atcid'].replace(/\t/g, '').replace(/\s/g, '') !== '' && item.report['atcid'].length > 2)?_c('span',[_vm._v(_vm._s(item.report['atcid']))]):(item.report['atc_flight_number'])?_c('span',[_vm._v(_vm._s(item.report['atc_flight_number']))]):_c('span',[_vm._v(_vm._s(item.title))])]}},{key:"item.vertical_speed_aircraft",fn:function(ref){
var item = ref.item;
return [_c('p',[('vertical_speed_touchdown_value' in item['report'])?_c('span',[_vm._v(" -"+_vm._s((item['report']['vertical_speed_touchdown_value'] * 60).toFixed(2))+" ")]):_c('span',[_vm._v(" "+_vm._s((item.report['vertical_speed_aircraft'] * 60).toFixed(2))+" ")])])]}},{key:"item.g_force",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item['report']['g_force'].toFixed(2))+" G")])]}},{key:"item.touchdown_speed",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item['report']['ground_speed'].toFixed(0))+" Kts")])]}},{key:"item.crosswind",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(Math.abs(item['report']['cross_wind']).toFixed(2))+" Kts")])]}},{key:"item.airport",fn:function(ref){
var item = ref.item;
return [(item.airport !== '')?_c('p',[_vm._v(_vm._s(item.airport))]):_c('p',[_vm._v("UNKNOWN")])]}},{key:"item.runway",fn:function(ref){
var item = ref.item;
return [(item['runway'] !== '')?_c('p',[_vm._v(_vm._s(item['runway']))]):_c('p',[_vm._v("UNKNOWN")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticStyle:{"margin-right":"5px"},attrs:{"color":"success"},on:{"click":function($event){return _vm.openLandingModal(item)}}},[_vm._v("View")]),_c('v-btn',{staticStyle:{"margin-right":"5px"},attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteLandingWithPopup(item.id)}}},[_vm._v("Delete")])],1)]}}])})],1)],1)],1),_c('ConfirmDialog',{ref:"confirmDialogRef"}),_c('LandingModal',{attrs:{"flightID":_vm.flightID,"landingID":_vm.landingID,"passedInLanding":_vm.landing,"dialog-state":_vm.dialogState},on:{"update:dialogState":function($event){_vm.dialogState=$event},"update:dialog-state":function($event){_vm.dialogState=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }