<template>
  <v-container>
    <v-tabs centered v-model="schedulesTab">
      <v-tab>Current Schedule</v-tab>
      <v-tab>Real Schedules</v-tab>
      <v-tab>Airline Routes</v-tab>
    </v-tabs>
    <v-tabs-items :touchless="true" v-model="schedulesTab">
      <v-tab-item>
        <FlightSchedules :tab.sync="tabPass" :dispatch-data.sync="dispatchDataPass" :dialog-state.sync="addFlightDialog" :schedule-data.sync="scheduleData" :fleet="fleet" :airports="airports"/>
      </v-tab-item>
      <v-tab-item>
        <RealFlights :tab.sync="schedulesTab" :dispatch-tab.sync="tabPass" :dispatch-data.sync="dispatchDataPass" :add-flight-schedule.sync="addFlightDialog" :schedule-data.sync="scheduleData" :airports="airports"/>
      </v-tab-item>
      <v-tab-item>
        <AirlineRoutes :tab.sync="schedulesTab" :dispatch-tab.sync="tabPass" :dispatch-data.sync="dispatchDataPass" :add-flight-schedule.sync="addFlightDialog" :schedule-data.sync="scheduleData" :airports="airports"/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import FlightSchedules from "@/components/FlightSchedules";
import RealFlights from "@/components/RealFlights";
import AirlineRoutes from "@/components/AirlineRoutes";
export default {
  name: "SchedulesTab",
  components: {AirlineRoutes, RealFlights, FlightSchedules},
  props: ['tab', 'dispatchData', 'fleet', 'airports'],
  data: function(){
    return {
      schedulesTab: null,
      addFlightDialog: false,
      scheduleData: null
    }
  },
  computed: {
    dispatchDataPass: {
      get: function(){
        return this.dispatchData
      },
      set: function (val){
        this.$emit('update:dispatchData', Object.assign({}, val))
      }
    },
    tabPass: {
      get: function(){
        return this.tab
      },
      set: function(val){
        this.$emit('update:tab', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
