<template>
  <v-dialog v-model="internalDialogState" width="500">
    <v-card>
      <v-card-title class="headline">
        Error
      </v-card-title>
      <v-card-text>
        An error occurred processing your request. <br>
        Error: {{errorMsg}}
      </v-card-text>
      <v-card-actions style="justify-content: center">
        <v-btn color="error" v-on:click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorDialog",
  props: ['dialogState'],
  data: function (){
    return {
      internalDialogState: false,
      errorMsg: "",
      resolve: null
    }
  },
  methods: {
    open: function (errorMsg){
      this.errorMsg = errorMsg
      this.internalDialogState = true
    },
    close(){
      this.internalDialogState = false
    },
  }
}
</script>

<style scoped>

</style>
