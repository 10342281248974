<template>
  <div>
    <!-- INITIAL FLIGHT CREATE PAGE -->
    <div v-if="!freeflight && activeFlight == null && (this.currentOFP == null && this.flightPlan == null )">
      <v-row>
        <v-col>
          <h3>Create Flight</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" cols="12" style="border-right: 1px solid;">
          <v-form v-model="validForm" ref="planningForm">
            <div style="text-align: center">
              <v-row style="align-items: center">
                <v-divider></v-divider>
                <h4>Required Fields</h4>
                <v-divider></v-divider>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete label="Origin" v-model="origin" :items="airportAutoCompleteItems" required :rules="requiredFieldRule"/>
                </v-col>
                <v-col>
                  <v-autocomplete label="Destination" v-model="destination" :items="airportAutoCompleteItems" required :rules="requiredFieldRule"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="Flight Number" v-model="flight_number" required :rules="requiredFieldRule"/>
                </v-col>
                <v-col>
                  <v-text-field label="Callsign" v-model="callsign" required :rules="requiredFieldRule"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete label="Aircraft" v-model="aircraftChosenID" :items="fleetWithAirline" item-text="name" item-value="id" required :rules="requiredFieldRule"/>
                </v-col>
              </v-row>
              <v-row style="align-items: center">
                <v-divider></v-divider>
                <h4>Optional Fields</h4>
                <v-divider></v-divider>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete label="Departure Runway" v-model="departureRwy" :items="departureRwys" required/>
                </v-col>
                <v-col>
                  <v-select label="SIDs" v-model="SIDs" :items="selectItemsBool" required/>
                </v-col>
                <v-col>
                  <v-autocomplete label="Arrival Runway" v-model="arrivalRwy" :items="arrivalRwys" required/>
                </v-col>
                <v-col>
                  <v-select label="STARs" v-model="STARs" :items="selectItemsBool" required/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select label="Plan Format" v-model="planFormat" :items="[{text:'LIDO', value: 'lido'}, {text:'AAL', value:'aal'}]" required/>
                </v-col>
                <v-col>
                  <v-select label="Plan Units" v-model="planUnits" :items="[{text:'LBS', value: 'lbs'}, {text:'KGS', value:'kgs'}]" required />
                </v-col>
              </v-row>
              <v-divider style="margin-bottom: 1rem;"></v-divider>
              <div class="centerActionsContainer">
                <v-btn color="success" :disabled="!validForm" v-on:click="createFlightPlan(origin, destination, callsign, reg, flight_number, aircraftType, SIDs, STARs, planUnits, planFormat, arrivalRwy, departureRwy)">Start Flight</v-btn>
                <v-btn color="error" v-on:click="resetFields">Reset</v-btn>
              </div>
            </div>
          </v-form>
        </v-col>
        <v-col md="6" cols="12" style="border-left: 1px solid;">
          <div style="text-align: center">
            <v-row style="align-items: center">
              <v-divider></v-divider>
              <h4>Load From SimBrief</h4>
              <v-divider></v-divider>
            </v-row>
            <p>Loads flight plan from your previously created plan on SimBrief.</p>

            <v-tooltip top :disabled="simbriefUserExists">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn color="success" :disabled="!simbriefUserExists" v-on:click="loadFromSimbrief()">Load from SimBrief</v-btn>
                </span>
              </template>
              <span>
                Requires username to be set in <router-link :to="{name: 'Account'}" style="text-decoration: none; color:inherit"> Account</router-link>
              </span>
            </v-tooltip>

          </div>
          <div style="text-align: center; margin-top: 1rem;margin-bottom: 12px">
            <v-row style="align-items: center">
              <v-divider/>
              <h4>Load MSFS Flight Plan</h4>
              <v-divider/>
            </v-row>
            <v-row>
              <v-col md="8" cols="12" offset-md="2">
                <p>Load MSFS PLN</p>

                <v-file-input v-model="file"
                              accept=".pln"
                              placeholder="Upload your .pln file"
                              label="File input"></v-file-input>

                <v-btn color="success" v-on:click="loadMSFSPlan()">Load MSFS Plan</v-btn>
              </v-col>
            </v-row>
          </div>
          <div style="text-align: center; margin-top: 1rem;margin-bottom: 12px">
            <v-row style="align-items: center">
              <v-divider></v-divider>
              <h4>FreeFlight</h4>
              <v-divider></v-divider>
            </v-row>
            <p>Starts a flight with no origin or destination selected</p>
            <v-btn color="success" v-on:click="startFreeflight()" align="center">Start Freeflight</v-btn>
          </div>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </div>
    <!-- SECONDARY PAGE -->
    <!-- Shows initial map / aircraft selection / edit -->
    <div v-else-if="(activeFlight == null && freeflight ) || (currentOFP != null || this.flightPlan != null ) && (activeFlight == null || editState )">
      <v-row>
        <v-col>
          <h3>Flight Plan</h3>
        </v-col>
        <v-col v-if="!editState" style="text-align: right">
          <v-menu offset-y v-if="downloads">
            <template v-slot:activator="{on,attrs}">
              <v-btn v-bind="attrs" v-on="on" style="margin-right: 5px;">
                Downloads
              </v-btn>
            </template>
            <v-list style="max-height: 30rem; overflow-y: auto">
              <v-list-item link style="min-height: 30px;" v-for="download in downloads" :href="download['link']">
                <v-list-item-title dense >{{download.name}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn v-on:click="resetFields()">Reset Plan</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete label="Aircraft" v-model="aircraftChosenID" :items="fleetWithAirline" item-text="name" item-value="id" required/>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <p>
            {{prettyRouteName.join(" ")}}
          </p>
          <p v-if="currentOFP != null">
            ETE ~{{getFormattedTotalTime(currentOFP)}}
          </p>
          <l-map :zoom="zoom" :center="mapCenter" style="height: 50vh; z-index: 0" gestureHandling :options="{gestureHandling: true, worldCopyJump: true}">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-polyline :lat-lngs="routeLines" color="#e4951b"></l-polyline>
            <l-circle-marker v-for="latLng in routeLines" :lat-lng="latLng" :radius="3" color="#1b6ae4"/>
          </l-map>
        </v-col>
      </v-row>

      <v-row class="align-center">
        <v-col md="6" v-if="requireRwySelection">
          <v-row style="align-items: center">
            <v-col>
              <v-autocomplete v-if="departureRwys.length !== 0" label="Departure Runway" v-model="departureRwy" :items="departureRwys" dense/>
            </v-col>
            <v-col>
              <v-autocomplete v-if="arrivalRwys.length !== 0" label="Arrival Runway" v-model="arrivalRwy" :items="arrivalRwys" dense/>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6" v-if="requireCallsignFlightNo">
          <v-row >
            <v-col class="align-center">
              <v-row>
                <v-col>
                 <v-select label="Flight Rules" v-model="flightRules" :items="flightRulesOptions" :rules="requiredFieldRule" dense/>
                </v-col>
                <template v-if="flightRules !== 'VFR'">
                  <v-col>
                    <v-text-field label="Callsign" v-model="callsign" required :rules="requiredFieldRule" dense/>
                  </v-col>
                  <v-col>
                    <v-text-field label="Flight Number" v-model="flight_number" required :rules="requiredFieldRule" dense/>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="!editState">
        <v-spacer></v-spacer>
        <v-btn color="primary" v-on:click="ofpDialogState = true" v-if="!freeflight && currentOFP !== null" style="margin-right: 1rem;">
          Show OFP
        </v-btn>
        <v-btn :disabled="!startFlightRequirementsMet" color="primary" v-on:click="startFlight()">Start flight</v-btn>
      </v-row>
      <v-row v-if="editState">
        <v-col class="align-center">
          <v-row style="align-items: center">
            <v-col>
              <v-autocomplete label="Departure Runway" v-model="departureRwy" :items="departureRwys" dense/>
            </v-col>
            <v-col>
              <v-autocomplete label="Arrival Runway" v-model="arrivalRwy" :items="arrivalRwys" dense/>
            </v-col>
          </v-row>
          <v-row class="actionsContainer">
            <v-btn color="warning" v-on:click="editState=false;editFlight()">Update</v-btn>
            <v-btn color="error" v-on:click="editState=false;">Cancel</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- ACTIVE PAGE -->
    <!-- Shows active flight page -->
    <div v-else-if="activeFlight != null">
      <div v-if="(currentOFP === null && flightPlan === null ) && !activeFlight.freeflight">
        Loading...
      </div>
      <div v-else>
        <v-row id="planningBtns" style="align-items: center;">
          <v-spacer></v-spacer>
          <v-tooltip v-model="show" bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary"  v-on:click="openShare(activeFlight['id']);">Share</v-btn>
            </template>
            <span> Copied link to clipboard! </span>
          </v-tooltip>


          <v-menu offset-y v-if="downloads">
            <template v-slot:activator="{on,attrs}">
              <v-btn v-bind="attrs" v-on="on">
                Downloads
              </v-btn>
            </template>
            <v-list style="max-height: 30rem; overflow-y: auto">
              <v-list-item link style="min-height: 30px;" v-for="download in downloads" :href="download['link']">
                <v-list-item-title dense >{{download.name}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn color="warning" v-on:click="editState = true;">Edit Flight</v-btn>
          <v-btn color="error" v-on:click="endFlight()">End Flight</v-btn>
        </v-row>
        <v-row>
          <v-col md="8" class="d-flex flex-column">
            <WebsocketData :url-input="[websocketDataKey, 'vatsimData']" :require-user-send="[true, false]" :enable-disable="[true, vatsimATC || vatsimFlights || vatsimATIS]" :data.sync="websocketData" tag="span" style="flex-grow: 1; display: flex; flex-direction: column; min-height: 50vh">
              <template slot-scope="{outData}">
                <template v-if="outData[websocketDataKey] !== undefined && outData[websocketDataKey] !== null && (currentData !== null || flownPath.length > 0)">
                  <l-map  ref="map1" :zoom="5" :center="liveMapCenter" style="z-index: 0" :options="{worldCopyJump: true}">
                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                    <l-tile-layer v-if="weatherMapURL != null" :url="weatherMapURL" layer-type="overlay" :visible="showWeather"/>
                    <l-polyline v-if="!activeFlight.freeflight" :lat-lngs="routeLines" color="green"></l-polyline>
                    <l-polyline :lat-lngs="flownPath"></l-polyline>
                    <l-circle-marker v-for="(latLng, index) in routeLines" :lat-lng="latLng" :radius="4" color="red">
                      <l-tooltip>
                        {{routeItemLabels[index]}}
                      </l-tooltip>
                    </l-circle-marker>
                    <l-control style="background-color: #484848; padding: .3rem; border-radius: .3rem">
                      <v-checkbox class="ma-0" label="Show Weather" v-model="showWeather"  dense hide-details/>
                      <v-checkbox class="ma-0" label="VATSIM Pilots" v-model="vatsimFlights" dense hide-details/>
                      <v-checkbox class="ma-0" label="VATSIM ATC" v-model="vatsimATC" dense hide-details/>
                      <v-checkbox class="ma-0" label="VATSIM ATIS" v-model="vatsimATIS" dense hide-details/>
                    </l-control>
                    <v-rotated-marker v-if="currentData !== null" :zIndexOffset="20000" style="z-index: 3" :lat-lng="markerPos(outData[websocketDataKey])" :icon="icon" :rotationAngle="parseInt(radians_to_degrees(outData[websocketDataKey]['heading']).toFixed(2))"></v-rotated-marker>

                    <template v-if="outData['vatsimData'] !== undefined && outData['vatsimData'] !== null">
                      <template v-if="vatsimATC">
                        <l-polygon v-for="boundaryControllers in outData['vatsimData']['active_fir_boundaries']" :lat-lngs="boundaryControllers['airspace_points']" :fillOpacity="0.1" :weight="1">
                          <l-tooltip>
                            <div>
                              <h3>{{boundaryControllers.icao}} Control</h3>
                            </div>
                            <p v-for="controller in boundaryControllers.controllers" style="padding:0;margin:0;">
                              <b>{{controller.callsign}}</b> - {{controller.frequency}} - {{controller.name}}
                            </p>
                          </l-tooltip>
                        </l-polygon>
                      </template>

                      <template v-if="vatsimATC">
                        <l-circle v-for="approachControllers in outData['vatsimData']['active_approach_controller']" :lat-lng="latitudeLongitude(approachControllers.latitude, approachControllers.longitude)" :radius="100000" :fillColor="'#FFFFFF'" :color="'#FFFFFF'" :weight="1">
                          <l-tooltip>
                            <div>
                              <h3>{{approachControllers.name}} Approach</h3>
                              <p v-for="controller in approachControllers.controllers" style="padding:0;margin:0;">
                                <b>{{controller.callsign}}</b> - {{controller.frequency}} - {{controller.name}}
                              </p>
                            </div>
                          </l-tooltip>
                        </l-circle>
                      </template>

                      <template v-for="airportControllers in outData['vatsimData']['active_airport_controllers']">
                        <template v-if="(vatsimATC && airportControllers.controllers.filter(c => c['atis']).length < airportControllers.controllers.length) || (vatsimATIS && airportControllers.controllers.filter(c => c['atis']).length > 0)">
                          <l-marker :lat-lng="latitudeLongitude(airportControllers.latitude, airportControllers.longitude)" :icon="towerIconMap" :zIndexOffset="10000">
                            <l-tooltip>
                              <div>
                                <h3>{{airportControllers['airport_name']}}</h3>
                                <template v-for="controller in airportControllers.controllers">
                                  <p v-if="(controller['atis'] && vatsimATIS) || (vatsimATC && !controller['atis'])" style="padding:0;margin:0;">
                                    <b>{{controller['callsign']}}</b> - {{controller['frequency']}} - {{controller['name']}}
                                  </p>
                                </template>
                              </div>
                            </l-tooltip>
                          </l-marker>
                        </template>
                      </template>

                      <template v-if="vatsimFlights">
                        <v-rotated-marker v-for="(pilot, pilotIdx) in outData['vatsimData']['pilots']" :lat-lng="latitudeLongitude(pilot.latitude, pilot.longitude)" :icon="greenIcon" :rotationAngle="pilot.heading">
                          <l-tooltip>
                            <span><b>[{{pilot['callsign']}}] {{pilot['name']}}</b> {{pilot['flight_plan']['aircraft']}}</span><br>
                            <span>{{pilot['flight_plan']['departure']}} > {{pilot['flight_plan']['arrival']}}</span><br>
                            <span>{{pilot['altitude']}}ft - {{pilot['groundspeed']}}Kts - {{pilot['heading']}}&#176;</span>
                          </l-tooltip>
                        </v-rotated-marker>
                      </template>
                    </template>
                  </l-map>
                </template>
                <template v-else>
                  <div style="text-align: center; align-items: center; justify-content: center; display: flex; flex-direction: column; flex-grow: 1">
                    <v-progress-circular
                      :size="150"
                      :width="5"
                      color="amber"
                      indeterminate
                    ></v-progress-circular>
                    <h1 style="margin: 0.67em">
                      Loading...
                    </h1>
                  </div>
                </template>
              </template>
            </WebsocketData>
            <div v-mutate="mutateMap" v-if="!freeflight">
              <v-row>
                <v-col md="6">
                  <template v-if="departureRwyObj != null && departureAirportObj != null">
                    <v-divider/>
                    <h4>Origin Runway</h4>
                    <v-divider/>
                    <div class="data-row">
                      <p class="alignedItems">
                        <span>Airport:</span>
                        <span class="limitTextSpan">{{departureAirportObj['name']}}</span>
                      </p>
                      <p class="alignedItems">
                        <span>Rwy:</span>
                        <span>{{departureRwyObj['runway_identifier']}}</span>
                      </p>
                    </div>
                    <div class="data-row">
                      <p class="alignedItems">
                        <span>Elevation:</span>
                        <span>{{departureRwyObj['elevation']}}</span>
                      </p>
                      <p class="alignedItems">
                        <span>Transition Altitude:</span>
                        <span>{{departureAirportObj['transition_altitude']}}</span>
                      </p>
                    </div>
                    <div class="data-row">
                      <p class="alignedItems">
                        <span>Length:</span>
                        <span>{{departureRwyObj['length']}}</span>
                      </p>
                      <p class="alignedItems">
                        <span>Width:</span>
                        <span>{{departureRwyObj['width']}}</span>
                      </p>
                    </div>
                    <div class="data-row">
                      <p class="alignedItems">
                        <span>Heading:</span>
                        <span>{{departureRwyObj['magnetic_bearing']}}</span>
                      </p>
                      <p class="alignedItems">
                        <span>Slope:</span>
                        <span>{{departureRwyObj['gradient']}}</span>
                      </p>
                    </div>
                    <div class="data-row" v-if="departureRwyObj['llz_identifier'] !== null">
                      <p class="alignedItems">
                        <span>Identifier:</span>
                        <span>{{departureRwyObj['llz_identifier']}}</span>
                      </p>
                      <p class="alignedItems">
                        <span>Category:</span>
                        <span>{{departureRwyObj['llz_mls_gls_category_text']}}</span>
                      </p>
                    </div>
                    <div class="data-row" v-if="departureRwyLocalizer != null">
                      <p class="alignedItems">
                        <span>Frequency:</span>
                        <span>{{departureRwyLocalizer['llz_frequency']}}</span>
                      </p>
                      <p class="alignedItems"></p>
                    </div>
                  </template>
                </v-col>
                <v-col md="6" v-if="arrivalRwyObj != null && arrivalAirportObj != null">
                  <v-divider/>
                  <h4>Destination Runway</h4>
                  <v-divider/>
                  <div class="data-row">
                    <p class="alignedItems">
                      <span>Airport:</span>
                      <span class="limitTextSpan">{{arrivalAirportObj['name']}}</span>
                    </p>
                    <p class="alignedItems">
                      <span>Rwy:</span>
                      <span>{{arrivalRwyObj['runway_identifier']}}</span>
                    </p>
                  </div>
                  <div class="data-row">
                    <p class="alignedItems">
                      <span>Elevation:</span>
                      <span>{{arrivalRwyObj['elevation']}}</span>
                    </p>
                    <p class="alignedItems">
                      <span>Transition Altitude:</span>
                      <span>{{arrivalAirportObj['transition_altitude']}}</span>
                    </p>
                  </div>
                  <div class="data-row">
                    <p class="alignedItems">
                      <span>Length:</span>
                      <span>{{arrivalRwyObj['length']}}</span>
                    </p>
                    <p class="alignedItems">
                      <span>Width:</span>
                      <span>{{arrivalRwyObj['width']}}</span>
                    </p>
                  </div>
                  <div class="data-row">
                    <p class="alignedItems">
                      <span>Heading:</span>
                      <span>{{arrivalRwyObj['magnetic_bearing']}}</span>
                    </p>
                    <p class="alignedItems">
                      <span>Slope:</span>
                      <span>{{arrivalRwyObj['gradient']}}</span>
                    </p>
                  </div>
                  <div class="data-row" v-if="arrivalRwyObj['llz_identifier'] !== null">
                    <p class="alignedItems">
                      <span>Identifier:</span>
                      <span>{{arrivalRwyObj['llz_identifier']}}</span>
                    </p>
                    <p class="alignedItems">
                      <span>Category:</span>
                      <span>{{arrivalRwyObj['llz_mls_gls_category_text']}}</span>
                    </p>
                  </div>
                  <div class="data-row" v-if="arrivalRwyLocalizer != null">
                    <p class="alignedItems">
                      <span>Frequency:</span>
                      <span>{{arrivalRwyLocalizer['llz_frequency']}}</span>
                    </p>
                    <p class="alignedItems"></p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col md="4"   style="height: 80vh; display: flex; flex-direction: column">
            <div style="font-size: 0.8rem; text-align: center" v-if="!activeFlight.freeflight && flightPlan != null &&  departureAirportObj != null && arrivalAirportObj != null ">
              <v-row style="align-items: center; margin:0">
                <div class="origDst">
                  <h1>{{flightPlan['origin']}}</h1>
                  <p style="margin-bottom: 0">{{departureAirportObj['name']}}</p>
                  <p>RWY {{departureRwy}}</p>
                </div>
                <div class="flex-grow-1">
                  <h4 style="text-align: center">Distance: {{distanceCovered.toFixed(2)}} / {{totalDistance.toFixed(2)}} nm</h4>
                  <v-progress-linear :value="percentProgress" striped height="10" style="width: auto; margin-left: 2rem; margin-right: 2rem"></v-progress-linear>
                  <h4 v-if="currentData !== null" style="margin-bottom: 0">
                    ETE: {{estimatedTimeRemaining}}
                  </h4>
                </div>
                <div class="origDst">
                  <h1>{{flightPlan['destination']}}</h1>
                  <p style="margin-bottom: 0">{{arrivalAirportObj['name']}}</p>
                  <p>RWY {{arrivalRwy}}</p>
                </div>
              </v-row>
              <p>
                {{prettyRouteName.join(" ")}}
              </p>
            </div>
            <div style="overflow-y: auto; height:100%; flex-grow: 1; margin-bottom: 1rem; padding: .5rem">
              <template v-if="!activeFlight.freeflight && flightPlan !== null && 'waypoints' in flightPlan">
                <div v-for="waypoint in flightPlan['waypoints']" style="background-color: #303030; border-radius: 1rem; padding: 1rem; margin:1rem;">
                  <div class="waypointDiv">
                    <h4>{{waypoint.label}}</h4>
                    <p>{{waypoint.lat}}, {{waypoint.long}}</p>
                    <p v-if="waypoint.heading_mag">Heading: {{waypoint.heading_mag}}&#176;</p>
                    <p v-if="waypoint.altitude_feet">Altitude: {{waypoint.altitude_feet}} ft</p>
                    <p v-if="waypoint.indicated_airspeed">Indicated Airspeed: {{waypoint.indicated_airspeed}} Kts</p>
                  </div>
                </div>
              </template>
            </div>
            <v-btn color="primary" v-on:click="ofpDialogState = true" v-if="!activeFlight.freeflight && currentOFP !== null">
              Open OFP
            </v-btn>

            <div>
              <div class="weatherActionContainer" style="display:flex; align-items: center">
                <v-text-field label="Airport ICAO" v-model="weatherAirportICAO"></v-text-field>
                <v-btn color="success" v-on:click="getMetar(weatherAirportICAO)">METAR</v-btn>
                <v-btn color="success" v-on:click="getTaf(weatherAirportICAO)">TAF</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <MetarModal :dialog-state.sync="metarDialogState" :metar-data="metarData"/>
    <TafModal :dialog-state.sync="tafDialogState" :taf-data="tafData"/>
    <ConfirmDialog ref="confirmDialogRef"/>
    <ErrorDialog ref="errorDialogRef" />
    <OFPModal :currentOFP="currentOFP" :ofpDialogState.sync="ofpDialogState"/>
  </div>
</template>

<script>

import L from "leaflet";
import {mapGetters} from "vuex";
import airplaneIcon from "@/assets/icons/airplane.png";
import FlightLogList from "@/components/FlightLogList";
import FlightModalGraph from "@/components/FlightModalGraph";

import {
  LCircle,
  LCircleMarker,
  LControlAttribution,
  LIcon,
  LMap,
  LMarker,
  LPolygon,
  LPolyline,
  LPopup,
  LTileLayer,
  LTooltip,
  LControl
} from "vue2-leaflet";
import WebsocketData from "@/components/WebsocketData";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import MetarModal from "./MetarModal";
import axios from "axios";
import OFPModal from "@/components/OFPModal";
import TafModal from "@/components/TafModal";
import Shared from "./Shared";
import ConfirmDialog from "./ConfirmDialog";
import airplaneIconGreen from "@/assets/icons/airplane-green.png";
import airplaneIconOrange from "@/assets/icons/airplane-orange.png";
import towerIcon from "@/assets/icons/tower.png";
import ErrorDialog from "@/components/ErrorDialog";

let baseURL = ''
if (process.env.NODE_ENV === 'production'){
  baseURL = new URL(window.location).origin
}else{
  baseURL = 'http://localhost:8080'
}

export default {
  name: "FlightPlanning",
  components: {
    ErrorDialog,
    TafModal,
    OFPModal,
    MetarModal,
    WebsocketData,
    FlightLogList,
    FlightModalGraph,
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LCircleMarker,
    LPopup,
    LTooltip,
    LControlAttribution,
    LControl,
    LIcon,
    LPolyline,
    LPolygon,
    'v-rotated-marker': Vue2LeafletRotatedMarker,
    ConfirmDialog
  },
  data: function (){
    return {
      origin: '',
      destination: '',
      flight_number: '',
      callsign: '',
      SIDs: true,
      STARs: true,
      planUnits: "kgs",
      planFormat: "lido",
      departureRwy: "",
      arrivalRwy: "",
      editState: false,
      validForm: false,

      freeflight: false,

      requireRwySelection: false,
      requireCallsignFlightNo: false,


      flightRules: "IFR",
      flightRulesOptions: ["IFR", "VFR"],

      requiredFieldRule: [
        v => !!v
      ],

      departureAirportObj: null,
      arrivalAirportObj: null,
      departureRwyObj: null,
      arrivalRwyObj: null,
      departureRwyLocalizer: null,
      arrivalRwyLocalizer: null,

      departureRwys: [],
      arrivalRwys: [],

      airlines: [],
      aircraftChosenID: null,

      weatherAirportICAO: "",

      metarData: "",
      metarDialogState: false,

      tafData: "",
      tafDialogState: false,

      selectItemsBool: [{text: 'Yes', value: true}, {text:'No', value: false}],
      flightPlan: null,
      currentOFP: null,
      ofpDialogState: false,

      file: null,
      uploadPercentage: 0,

      flownPath: [],
      websocketData: {},
      websocketDataKey: 'web?hide_last=false',

      show: false,

      zoom:5,
      liveMapCenter: L.latLng(0, 0),
      url:'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
      prefix: "",
      attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      icon: L.icon({
        iconUrl: airplaneIcon,
        iconSize: [36, 36],
        iconAnchor: [18, 18]
      }),

      greenIcon: L.icon({
        iconUrl: airplaneIconGreen,
        iconSize: [18, 18],
        iconAnchor: [9, 9]
      }),
      orangeIcon: L.icon({
        iconUrl: airplaneIconOrange,
        iconSize: [18, 18],
        iconAnchor: [9, 9]
      }),
      towerIconMap: L.icon({
        iconUrl: towerIcon,
        iconSize: [26, 26],
        iconAnchor: [13, 13]
      }),

      liveMapCenterInitToUser: false,

      showWeather: true,
      weatherUpdateTimer: null,
      latestWeatherTS: 0,

      vatsimATC: false,
      vatsimFlights: false,
      vatsimATIS: false,
    }
  },
  props: ['flightsProp', 'dispatchData', 'fleet', 'airports', 'tab'],
  created: function() {
    this.updateWeatherTimestamp()
    if (this.activeFlight != null){
      this.getOFP(this.activeFlight['id'])
      this.getFlightPlan(this.activeFlight['id'])
      this.loadFlownPath(this.activeFlight['id'])
    }

  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    currentData: function(){
      if (this.websocketData === null ||
        Object.keys(this.websocketData).length === 0 ||
        this.websocketData[this.websocketDataKey] === null ||
        this.websocketData[this.websocketDataKey] === undefined){
        return null
      }

      return this.websocketData[this.websocketDataKey]
    },
    weatherMapURL: function(){
      if (this.latestWeatherTS === 0){
        return null
      }
      return 'https://tilecache.rainviewer.com/v2/radar/'+ this.latestWeatherTS +'/512/{z}/{x}/{y}/6/1_1.png'
    },
    fleetWithAirline: function(){
      const fleetItems = this.fleet
      let items = []

      fleetItems.forEach(item => {
        items.push({
          'id': item['aircraft']['id'],
          'name': item['airframe']['icao'] + " - " + item['aircraft']['registration'] + " - " + item['airline']['name']
        })
      })

      return items
    },
    aircraftType: function(){
      if (this.aircraftChosenID === null){
        return ""
      }

      return this.fleet.filter(data => data['aircraft']['id'] === this.aircraftChosenID)[0]['airframe']['type']
    },
    reg: function(){
      if (this.aircraftChosenID === null){
        return ""
      }

      return this.fleet.filter(data => data['aircraft']['id'] === this.aircraftChosenID)[0]['aircraft']['registration']
    },
    mapCenter: function(){
      return this.routeLines[0]
    },
    activeFlight: function () {
      if (this.flights != null) {
        const activeFlight = this.flights.filter(flight => flight['in_flight'])
        if (activeFlight.length === 0){
          return null
        }else{
          const flight = activeFlight[0]
          console.log("Flight ID: " + flight['id'])
          this.origin = flight['origin']
          this.destination = flight['destination']
          this.departureRwy = flight['origin_rwy']
          this.arrivalRwy = flight['destination_rwy']
          this.aircraftChosenID = flight['aircraft_id']
          return flight
        }
      }
      return null
    },
    flights: {
      get: function () {
        return this.flightsProp
      },
      set: function (val) {
        this.$emit('update:flightsProp', val)
      }
    },
    routeLines: function () {
      const route = this.generateFullRoutePlan(this.flightPlan)
      return route.map(item => L.latLng(item['lat'], item['long']))
    },
    routeItemLabels: function (){
      const route = this.generateFullRoutePlan(this.flightPlan)
      return route.map(item => item['name'])
    },
    destinationPlanLatLong: function(){
      if (this.flightPlan === null || !('destination_lat' in this.flightPlan) || !('destination_long' in this.flightPlan)){
        return null
      }
      return {lat: this.flightPlan['destination_lat'], long: this.flightPlan['destination_long']}
    },
    airportAutoCompleteItems: function(){
      return this.airports.map(a => {
        return {text: a.identifier + " - " + a.name, value: a.identifier}
      }).sort(function (a, b){
        const textA = a["text"]
        const textB = b["text"]
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      })
    },
    prettyRouteName: function(){
      const flightPlan = this.flightPlan
      let routeNames = []

      if (flightPlan == null){
        return routeNames
      }

      routeNames.push(flightPlan['origin'] + "/" + this.departureRwy)
      routeNames.push(...flightPlan['route'].split(" "))
      routeNames.push(flightPlan['destination'] + "/" + this.arrivalRwy)

      return routeNames
    },
    downloads: function(){
      const flightPlan = this.currentOFP
      if (flightPlan == null){
        return null
      }
      const flightPlanDownloads = flightPlan['fms_downloads']
      const linkPrefix = flightPlanDownloads['directory']
      let downloads = []
      for (const [key, value] of Object.entries(flightPlanDownloads)){
        if (typeof value === 'object' && value !== null && value.hasOwnProperty('name') && value.hasOwnProperty('link') && value['name'] !== "" && value['link'] !== "") {
          downloads.push({"name": value["name"], "link": linkPrefix + value["link"]})
        }
      }
      return downloads
    },
    totalDistance: function(){
      if (this.flightPlan != null) {
        return Shared.methods.calcDistance(this.flightPlan['origin_lat'], this.flightPlan['origin_long'], this.flightPlan['destination_lat'], this.flightPlan['destination_long'])
      }
      return 0
    },
    lastPosition: function(){
      if (this.currentData !== null){
        let lastData = this.currentData
        return [lastData['latitude'], lastData['longitude']]
      }
      return null
    },
    remainingDistance: function(){
      if (this.flightPlan != null){
        let destination = [this.flightPlan['destination_lat'], this.flightPlan['destination_long']]
        let lastPosition = this.lastPosition
        if (lastPosition !== null){
          return Shared.methods.calcDistance(lastPosition[0], lastPosition[1], destination[0], destination[1])
        }
      }
      return 0
    },
    distanceCovered: function(){
      if (this.flightPlan != null){
        return Math.max(this.totalDistance - this.remainingDistance,0)
      }
      return 0
    },
    percentProgress: function(){
      return ((this.totalDistance - this.remainingDistance) / this.totalDistance) * 100
    },
    estimatedTimeRemaining: function(){
      if (this.currentData != null) {
        return Shared.methods.formatHoursToTime(this.remainingDistance / this.currentData['ground_speed'])
      }
      return 0
    },
    arrivalRwyRequirementsMet: function(){
      return this.arrivalRwy !== '' || this.arrivalRwys.length === 0
    },
    departureRwyRequirementsMet: function(){
      return this.departureRwy !== '' || this.departureRwys.length === 0
    },
    startFlightRequirementsMet: function(){
      let arrivalRwyMet = this.arrivalRwyRequirementsMet
      let departureRwyMet = this.departureRwyRequirementsMet
      let callsignMet = this.callsign !== '' || this.flightRules === "VFR"
      let flightNumMet = this.flight_number !== '' || this.flightRules === "VFR"

      return this.aircraftChosenID !== null && arrivalRwyMet && departureRwyMet && callsignMet && flightNumMet
    },
    simbriefUserExists: function(){
      return !(this.currentUser === null ||
        this.currentUser['simbriefUser'] === undefined ||
        this.currentUser['simbriefUser'] === '');
    }
  },
  watch: {
    showWeather: function(val){
      if (val){
        this.weatherUpdateTimer = setInterval(this.updateWeatherTimestamp, 10000)
      }else{
        clearInterval(this.weatherUpdateTimer)
      }
    },
    currentData: function(val){
      if (val !== null ) {
        this.flownPath.push([val['latitude'], val['longitude']])
      }
    },
    dispatchData: function(val){
      if (val){
        const airline = val['airline']
        this.origin = val['origin']
        this.destination = val['destination']
        this.flight_number = airline['iata'] + val['flight_number']
        this.callsign = airline['icao'] + val['flight_number']
        if (val.hasOwnProperty('aircraft_id')) {
          this.aircraftChosenID = val['aircraft_id']
        }else{
          this.aircraftChosenID = null
        }
        this.$refs.planningForm.resetValidation()
      }
    },
    origin: function (val){
      fetch(baseURL+"/runways?airport=" + val)
        .then(response => {
          if (response.ok){
            response.json().then(data => {
              if (data === null){
                this.departureRwys = []
                return
              }
              this.departureRwys = data.map(r => {
                return {
                  text: r['runway_identifier'],
                  value: r['runway_identifier'].replace('RW', ''),
                }
              }).sort()
            })
          }
        })
      this.departureAirportObj = this.airports.filter(data => data['identifier'] === this.origin)[0]
    },
    destination: function (val){
      fetch(baseURL+"/runways?airport=" + val)
        .then(response => {
          if (response.ok){
            response.json().then(data => {
              if (data === null){
                this.arrivalRwys = []
                return
              }
              this.arrivalRwys = data.map(r => {
                return {
                  text: r['runway_identifier'],
                  value: r['runway_identifier'].replace('RW', ''),
                }
              }).sort()
            })
          }
        })
      this.arrivalAirportObj = this.airports.filter(data => data['identifier'] === this.destination)[0]
    },
    airports: function (val){
      this.departureAirportObj = this.airports.filter(data => data['identifier'] === this.origin)[0]
      this.arrivalAirportObj = this.airports.filter(data => data['identifier'] === this.destination)[0]
    },
    departureRwy: function(val){
      if (this.origin === null || val === ""){
        return
      }
      fetch(this.$baseURL + "/runway?airport="+this.origin+"&runway=RW"+val)
        .then(response => {
          if (response.ok){
            response.json().then(data => {
              if (data != null){
                this.departureRwyObj = data
              }
            })
          }
        })
      fetch(this.$baseURL + "/localizerGlideslope?airport="+this.origin+"&runway=RW"+val)
        .then(response => {
          if (response.ok){
            response.json().then(data => {
              if (data != null){
                this.departureRwyLocalizer = data
              }else{
                this.departureRwyLocalizer = null
              }
            })
          }
        })
    },
    arrivalRwy: function(val){
      if (this.destination === null || val === ""){
        return
      }
      fetch(this.$baseURL + "/runway?airport="+this.destination+"&runway=RW"+val)
        .then(response => {
          if (response.ok){
            response.json().then(data => {
              if (data != null){
                this.arrivalRwyObj = data
              }
            })
          }
        })
      fetch(this.$baseURL + "/localizerGlideslope?airport="+this.destination+"&runway=RW"+val)
        .then(response => {
          if (response.ok){
            response.json().then(data => {
              if (data != null){
                this.arrivalRwyLocalizer = data
              }else{
                this.arrivalRwyLocalizer = null
              }
            })
          }
        })
    },
    weatherAirportICAO: function(val){
      this.weatherAirportICAO = val.toUpperCase()
    },
  },
  methods: {
    getOFP: function(flightID){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(baseURL + "/" + this.currentUser['user'] +"/flightOFP?flightID="+flightID, requestOptions)
        .then(response => response.json())
        .then(function (data){
          if (data['result'] !== null && data['error'] === "") {
            this.currentOFP = data['result']['OFP']
          }
        }.bind(this))
    },
    getFlightPlan: function(flightID){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
      fetch(baseURL + "/getFlightPlan?flightID="+flightID + "&username="+this.currentUser['user'], requestOptions)
        .then(response => response.json())
        .then(function (data){
          if (data['result'] !== null && data['error'] === "") {
            this.flightPlan = data['result']
          }
        }.bind(this))
    },
    loadFlownPath: function(flightID){
      fetch(baseURL+"/" + this.currentUser['user'] + "/userFlightData?flightID="+flightID)
        .then(response => {
          if (response.ok){
            return response.json().then(data => {
              if (data !== null && 'flight_data_arr' in data && data['flight_data_arr'] !== null) {
                const latLngs = data['flight_data_arr'].map(run => [run['latitude'], run['longitude']])
                this.flownPath.unshift(...latLngs)

                if(!this.liveMapCenterInitToUser){
                  this.liveMapCenter = L.latLng(this.flownPath[0][0], this.flownPath[0][1])
                }
              }
            })
          }
          console.error(response.status)
        })
    },
    boolToInt: function(bool){
      if (bool){
        return 1
      }
      return 0
    },
    calcDistance: function (lat1, lon1, lat2, lon2){

      function toRad(Value)
      {
        return Value * Math.PI / 180;
      }

      const R = 3440; // nautical miles
      const dLat = toRad(lat2-lat1);
      const dLon = toRad(lon2-lon1);
      lat1 = toRad(lat1);
      lat2 = toRad(lat2);

      const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      return R * c;
    },
    createFlightPlan: function(orig, dest, callsign, reg, flight_number, type, sids, stars, planUnit, planFormat, arrRwy, depRwy){
      const timestamp = Math.round(+new Date()/1000)

      // Output URL is required to be sent but we don't use it
      let output = "null"
      output = encodeURI(output)

      // Get MD5 encoding for apicode
      let apiCode;
      fetch(baseURL + "/apiReq?apiReq="+orig+dest+type+timestamp+output)
        .then(response => response.json())
        .then(data => {
          apiCode = data['apiReq']
          startWorkerWindow()
        })

      let workerLoop;
      let workerWindow;

      let parent = this;

      // Build URL for pop up window to initiate API request
      function startWorkerWindow(){
        let nextURL = "https://www.simbrief.com/ofp/ofp.loader.api.php?type="+type+
          "&orig="+orig+
          "&dest="+dest+
          "&apicode="+apiCode+
          "&outputpage="+output+
          "&timestamp="+timestamp+
          "&callsign="+callsign+
          "&fltnum="+flight_number+
          "&omit_sids="+parent.boolToInt(!sids)+
          "&omit_stars=" + parent.boolToInt(!stars)+
          "&planformat="+planFormat+
          "&units="+planUnit+
          "&reg="+reg

        if (depRwy !== ""){
          nextURL += "&origrwy=" + depRwy
        }

        if (arrRwy !== ""){
          nextURL += "&destrwy=" + arrRwy
        }

        workerWindow = window.open(nextURL, '_blank', 'width=600,height=315')
        workerLoop = window.setInterval(checkWorkerWindowComplete, 500)
      }

      function checkWorkerWindowComplete(){
        if (workerWindow && workerWindow.closed){
          clearInterval(workerLoop)
          const ofpIDPromise = calculateOFPID()
          getOFP(ofpIDPromise)
        }
      }

      function calculateOFPID(){
        return fetch(baseURL + "/ofp?timestamp=" + timestamp + "&orig=" + orig + "&dest=" + dest + "&type=" + type)
      }

      function getOFP(ofpIDPromise){
        ofpIDPromise
          .then(response => response.json())
          .then(data => {
            const ofpID = data['ofpID']
            const getOFPURL = baseURL + "/get_ofp?ofp_id=" + ofpID
            fetch(getOFPURL)
              .then(response => response.json())
              .then(data => {
                parent.currentOFP = data['ofp']
                parent.flightPlan = data['flightPlan']

                parent.departureRwy = parent.flightPlan['origin_rwy']
                parent.arrivalRwy = parent.flightPlan['destination_rwy']
              })
          })
      }
    },
    loadFromSimbrief: function (){
      const simbriefUser = this.currentUser['simbriefUser']
      const getOFPURL = baseURL + "/get_ofp?username=" + simbriefUser
      fetch(getOFPURL)
        .then(response => response.json())
        .then(function (data){
          if (data['error'] === "") {
            const ofp = data['ofp']
            this.flightPlan = data['flightPlan']
            this.origin = this.flightPlan['origin']
            this.destination = this.flightPlan['destination']
            this.departureRwy = this.flightPlan['origin_rwy']
            this.arrivalRwy = this.flightPlan['destination_rwy']
            this.callsign = this.flightPlan['callsign']
            this.flight_number = this.flightPlan['airline'] + this.flightPlan['flight_number']
            this.currentOFP = ofp
            if (ofp !== null && ofp !== undefined){
              const ofpReg =  ofp['aircraft']['reg']
              const filteredAircraft = this.fleet.filter(aircraft => aircraft['aircraft']['registration'] === ofpReg)
              if (filteredAircraft.length === 1){
                this.aircraftChosenID = filteredAircraft[0]['aircraft']['id']
              }
            }
          }else{
            this.$refs.errorDialogRef.open(data['error'])

          }
        }.bind(this))
    },
    resetFields: function (){
      this.origin = null
      this.destination = null
      this.flight_number = ""
      this.callsign = ""
      this.currentOFP = null
      this.flightPlan = null
      this.freeflight = false
      this.departureRwy = ""
      this.arrivalRwy = ""
      this.chocolate = ""
      this.uploadPercentage = 0
      this.file = null
      this.aircraftChosenID = null
      this.flownPath = []
      if (this.$refs.planningForm !== undefined) {
        this.$refs.planningForm.resetValidation()
      }
      this.requireRwySelection = false
      this.requireCallsignFlightNo = false

      this.departureAirportObj = null
      this.arrivalAirportObj = null
      this.departureRwyLocalizer = null
      this.arrivalRwyLocalizer = null

      this.flightRules = "IFR"
    },
    getTotalTime: function (flightPlan){
      let total = 0
      if (flightPlan['nav_log']['fix'] == null){
        return flightPlan['times']['est_time_enroute']
      }
      for (let i = 0; i < flightPlan['nav_log']['fix'].length; i++){
        const fix = flightPlan['nav_log']['fix'][i]
        total += fix['time_leg']
      }
      return total
    },
    getFormattedTotalTime: function (flightPlan){
      const totalTime = this.getTotalTime(flightPlan)
      return new Date(totalTime * 1000).toISOString().substr(11, 8)
    },
    formatHoursToTime: function(hoursIn){
      return Shared.methods.formatHoursToTime(hoursIn)
    },
    startFreeflight: function(){
      this.origin = "FREEFLIGHT"
      this.destination = "FREEFLIGHT"
      this.freeflight = true

      if (this.arrivalRwy === '' || this.departureRwy === ''){
        this.requireRwySelection = true
      }

      if (this.flight_number === '' || this.callsign === ''){
        this.requireCallsignFlightNo = true
      }
    },
    startFlight: function (){
      let body = Object.assign({}, this.currentUser)

      const aircraft = this.fleet.filter(d => d.aircraft.id === this.aircraftChosenID)
      if (aircraft.length !== 1){
        console.error("Error obtaining aircraft")
        return
      }

      if (this.flightRules === "VFR"){
        this.callsign = aircraft[0].aircraft.registration
        this.flight_number = aircraft[0].aircraft.registration
      }

      body['origin'] = this.origin
      body['destination'] = this.destination
      body['flight_number'] = this.flight_number
      body['callsign'] = this.callsign
      body['originRwy'] = this.departureRwy
      body['destinationRwy'] = this.arrivalRwy
      body['aircraftID'] = this.aircraftChosenID
      body['is_vfr'] = this.flightRules === 'VFR'

      if (!this.freeflight) {
        const source = this.flightPlan['source']
        body['planSource'] = source

        if (source === "OFP") {
          body['ofp'] = this.currentOFP
        }
        if (source === "MSFSPLN") {
          body['flightPlan'] = this.flightPlan
        }
      }

      if (this.freeflight){
        body['freeflight'] = this.freeflight
      }

      const bodyJson = JSON.stringify(body)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
        body: bodyJson
      }
      fetch(baseURL+"/"+this.currentUser['user']+"/startFlight", requestOptions)
        .then(response => {
          if (response.ok){
            return response.json().then(data => {
              this.flights = data['user_flights_doc']['flights']
            })
          }
          console.error(response.status)
        })
    },
    editFlight: function(){
      let body = Object.assign({}, this.currentUser)
      body['originRwy'] = this.departureRwy
      body['destinationRwy'] = this.arrivalRwy
      body['flightID'] = this.activeFlight['id']
      body['aircraftID'] = this.aircraftChosenID

      const bodyJson = JSON.stringify(body)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
        body: bodyJson
      }
      fetch(baseURL+"/"+this.currentUser['user']+"/updateRunways", requestOptions)
        .then(response => response.json())
        .then(data => {
        })
    },
    endFlight: function(){
      this.$refs.confirmDialogRef.run().then(function(confirmRes){
        if (confirmRes){
          let body = Object.assign({}, this.currentUser)
          body['flight_id'] = this.activeFlight['id']
          const bodyJson = JSON.stringify(body)
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Token " + this.currentUser['tokenHash']
            },
            body: bodyJson
          }
          fetch(baseURL + "/" + this.currentUser['user'] +"/endFlight", requestOptions)
            .then(response => {
              if (response.ok){
                return response.json().then(data => {
                  this.flights = data['flights']
                  this.resetFields()
                })
              }
            })
        }
      }.bind(this))
    },
    generateFullRoutePlan: function(flightPlan){
      let route = []

      if (flightPlan === null){
        return route
      }

      route.push({
        name: flightPlan['origin'] + "/" + flightPlan['origin_rwy'],
        lat: flightPlan['origin_lat'],
        long: flightPlan['origin_long']
      })

      for (let i = 0; i < flightPlan['waypoints'].length; i++){
        const waypoint = flightPlan['waypoints'][i]
        route.push({
          name: waypoint['label'],
          lat: waypoint['lat'],
          long: waypoint['long']
        })
      }

      route.push({
        name: flightPlan['destination'] + "/" + flightPlan['destination_rwy'],
        lat: flightPlan['destination_lat'],
        long: flightPlan['destination_long']
      })

      return route

    },
    dispatchDataToClient: function(payload){
      let body = {
        'payload': payload
      }
      const bodyJSON = JSON.stringify(body)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash'],
        },
        body: bodyJSON
      }
      fetch(this.$baseURL + "/" + this.currentUser['user'] + "/clientDispatch", requestOptions)
        .then(response => response.json())
        .then (data => {
          // Not used atm
        })
    },
    getAirportMetar: function(airportICAO){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }
      return fetch(this.$baseURL + "/metar?airportICAO="+airportICAO, requestOptions)
        .then(response => response.json())
    },
    getMetar: function(airportICAO){
      this.getAirportMetar(airportICAO)
        .then(data => {
          this.metarData = data['result']
          this.metarDialogState = true
        })
    },
    getTaf: function(airportICAO){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }
      fetch(this.$baseURL + "/taf?airportICAO="+airportICAO, requestOptions)
        .then(response => response.json())
        .then(data => {
          this.tafData = data['result']
          this.tafDialogState = true
        })
    },
    radians_to_degrees: function(radians)
    {
      let pi = Math.PI;
      return radians * (180/pi);
    },
    markerPos: function(data){
      if (data === undefined || data === null || !('latitude' in data)){
        return L.latLng(0,0)
      }

      const markerPos = L.latLng(data['latitude'].toFixed(6), data['longitude'].toFixed(6))

      if (!this.liveMapCenterInitToUser){
        this.liveMapCenter = markerPos
        this.liveMapCenterInitToUser = true
      }

      return markerPos
    },
    loadMSFSPlan: function(){
      let formData = new FormData();
      formData.append('flightPlan', this.file)

      axios.post(this.$baseURL + '/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
          }.bind(this)
        }
      ).then(function(data){
        this.flightPlan = data.data['result']

        this.origin = this.flightPlan['origin']
        this.destination = this.flightPlan['destination']

        if (this.arrivalRwy === '' || this.departureRwy === ''){
          this.requireRwySelection = true
        }

        if (this.flight_number === '' || this.callsign === ''){
          this.requireCallsignFlightNo = true
        }

        }.bind(this))
      .catch(function(){
        console.log('Fail')
      });
    },
    openShare: function(flightID){
      let page = this.$router.resolve({name: "FlightShare", params: {flightID: flightID}})
      navigator.clipboard.writeText( location.origin + page.href)

      this.show = true

      setTimeout(function (){this.show = false}.bind(this), 1000)

      return true
    },
    mutateMap: function(){
      if ('map1' in this.$refs) {
        this.$refs.map1.mapObject.invalidateSize()
      }
    },
    updateWeatherTimestamp: function(){
      fetch("https://tilecache.rainviewer.com/api/maps.json")
        .then(response => response.json())
        .then(data =>{
          this.latestWeatherTS = data[data.length - 1]
        })
    },
    latitudeLongitude: function(lat, long){
      return L.latLng(lat, long)
    }
  }
}
</script>

<style scoped>
#planningBtns button{
  margin-left:0.2rem;
  margin-bottom: 0.2rem;
}

.data-row{
  padding-left:15px;
  padding-right: 15px;
  margin-left:-15px;
  margin-right:-12px;
  display: flex;
}

.data-row p{
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  margin-left:15px;
  margin-right: 15px;
}

.alignedItems {
  margin-bottom: 0 !important;
}

.alignedItems span:first-child{
  text-align: left;
}

.alignedItems span:last-child{
  text-align: right;
  flex-grow: 1;
}

.actionsContainer{
  display: flex;
  justify-content: flex-end;
}

.centerActionsContainer .v-btn{
  margin: 5px;
}

.actionsContainer .v-btn{
  margin: 5px;
}

.weatherActionContainer *{
  margin: 5px;
}

.waypointDiv p{
  margin: 0;
}

.origDst{
  width: 25%;
}

.origDst p{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.limitTextSpan {
  display: inline-block;
  margin-left: 10px;
  overflow: hidden;
  width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}



</style>
